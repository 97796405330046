import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApplicationRole } from '../enums';
import { User } from '../models';
import { UsersService } from '../services/users.service';

@Injectable({ providedIn: 'root' })
export class UserGuard implements CanActivate {
  constructor(private usersService: UsersService) {}

  canActivate(): Observable<boolean> {
    return this.resolve();
  }
  canActivateChild(): Observable<boolean> {
    return this.resolve();
  }

  private resolve() {
    return this.usersService.currentUser$.pipe(
      filter((user) => !!user),
      map((user) => !!user),
    );
  }
}
