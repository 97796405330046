<div class="card" id="purchase-orders">
  <h5 class="card-header diog-grid-header">
    <div class="card-title">
      Receiving Documents <span class="badge badge-pill badge-secondary">{{ (gridData$ | async)?.total }}</span>
    </div>
    <div class="card-controls d-inline-flex">
      <app-grid-filter-input
        [ngModel]="filterValue$ | async"
        #filter
        (keyup)="filterPurchaseOrders(filter.value)"
        style="margin-right: 10px;"
      ></app-grid-filter-input>
      <ng-form [formGroup]="form" *ngIf="(userSites$ | async)?.length > 1">
        <kendo-combobox
          [placeholder]="'Site Filter'"
          id="site-picker"
          [data]="userSites$ | async"
          [valueField]="'id'"
          [textField]="'name'"
          [filterable]="true"
          formControlName="siteFilter"
          [valuePrimitive]="true"
          [clearButton]="true"
          [suggest]="false"
        >
        </kendo-combobox>
      </ng-form>
      <div>
        &nbsp;
        <button type="button" (click)="openNewDialog()" class="btn btn-outline-primary">
          <i class="far fa-plus"></i> New
        </button>
      </div>
    </div>
  </h5>

  <kendo-grid
    [data]="gridData$ | async"
    [rowHeight]="rowHeight"
    style="height: calc(100vh - 180px);"
    [resizable]="true"
    [sortable]="{
      allowUnsort: true,
      mode: 'single'
    }"
    [sort]="sort$ | async"
    [scrollable]="'virtual'"
    [loading]="!(loaded$ | async)"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="skip$ | async"
    (sortChange)="sortChange($event)"
    (pageChange)="pageChange($event)"
  >
    <kendo-grid-column field="number" title="Receiving Document">
      <ng-template kendoGridCellTemplate let-purchaseOrder>
        <div>
          <a class="pointer text-primary" [routerLink]="['/receiving-document', purchaseOrder.id]">
            {{ purchaseOrder.number }}
          </a>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="status" title="Status"></kendo-grid-column>
    <kendo-grid-column field="itemCount" title="Item Count"></kendo-grid-column>
    <kendo-grid-column field="totalCost" title="Total Cost">
      <ng-template kendoGridCellTemplate let-purchaseOrder>
        {{ purchaseOrder.totalCost | currency }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="ripNumber" title="RIP"></kendo-grid-column>
    <kendo-grid-column field="vendorName" title="Vendor"></kendo-grid-column>
    <kendo-grid-column field="projectCode" title="Project"></kendo-grid-column>
    <kendo-grid-column field="siteName" title="Site"></kendo-grid-column>
    <kendo-grid-column field="classification" title="Classification"></kendo-grid-column>
    <kendo-grid-column field="createdBy" title="Created By">
      <ng-template kendoGridCellTemplate let-purchaseOrder>
        <app-user-name [userId]="purchaseOrder.createdBy"></app-user-name>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="130" field="createdTime" title="Created">
      <ng-template kendoGridCellTemplate let-purchaseOrder>
        {{ purchaseOrder.createdTime | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedBy" title="Updated By">
      <ng-template kendoGridCellTemplate let-purchaseOrder>
        <app-user-name [userId]="purchaseOrder.updatedBy"></app-user-name>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="130" field="updatedTime" title="Updated">
      <ng-template kendoGridCellTemplate let-purchaseOrder>
        {{ purchaseOrder.updatedTime | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100" title="Actions" *ngIf="currentUserCanEdit$ | async">
      <ng-template kendoGridCellTemplate let-purchaseOrder>
        <div class="no-wrap">
          <button
            (click)="openEditDialog(purchaseOrder)"
            title="Edit"
            type="button"
            class="btn btn-outline-primary btn-sm no-wrap"
          >
            <i class="far fa-edit"></i>
          </button>
          &nbsp;
          <button
            (click)="delete(purchaseOrder)"
            *ngIf="currentUserIsAdmin$ | async"
            title="Delete"
            type="button"
            class="btn btn-outline-danger btn-sm no-wrap"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
