import * as _ from 'lodash';
import {
  Building,
  Custodian,
  Item,
  ItemExtended,
  Manufacturer,
  Part,
  Project,
  PurchaseOrder,
  PurchaseOrderExtended,
  Report,
  Rip,
  Room,
  ShippingLocation,
  ShippingOrder,
  ShippingOrderExtended,
  Site,
  SubCustodyAssignment,
  User,
  Vendor,
} from '../models';

export function buildingFilter(entities, search = '') {
  return filterEntities(entities, ['name'], search);
}

export function buildingSort(a: Building, b: Building): number {
  return a.name.localeCompare(b.name);
}
export function custodianFilter(entities, search = '') {
  return filterEntities(entities, ['fistName', 'lastName', 'emailAddress', 'phoneNumber', 'notes'], search);
}

export function custodianSort(a: Custodian, b: Custodian): number {
  return a.lastName.localeCompare(b.lastName);
}

export function itemFilter(entities, search = '') {
  return filterEntities(
    entities,
    [
      'assignedTo',
      'buildingName',
      'comments',
      'componentType',
      'hsCode',
      'itarEccn',
      'location',
      'manufactureName',
      'modelNumber',
      'nhaSmxId',
      'orderQuantity',
      'partConditionCode',
      'partDescription',
      'partNsn',
      'partNumber',
      'projectCode',
      'projectContractNumber',
      'projectTdlNumber',
      'purchaseOrderClassification',
      'purchaseOrderNumber',
      'purchaseOrderRipNumber',
      'roomName',
      'serialNumber',
      'smxId',
      'status',
      'totalCost',
      'unitPrice',
      'vendorName',
    ],
    search,
  );
}

export function itemSort(a: ItemExtended, b: ItemExtended) {
  return _.get(a, 'partNumber') ? a.partNumber.localeCompare(_.get(b, 'partNumber')) : 1;
}

export function manufacturerFilter(entities, search = '') {
  return filterEntities(entities, ['name'], search);
}

export function manufacturerSort(a: Manufacturer, b: Manufacturer) {
  return a.name.localeCompare(b.name);
}

export function partFilter(entities, search = '') {
  return filterEntities(entities, ['manufacturer.name', 'partNumber', 'description', 'nsn'], search);
}

export function partSort(a: Part, b: Part): number {
  return a.partNumber ? a.partNumber.localeCompare(b.partNumber) : 1;
}

export function projectFilter(entities, search = '') {
  return filterEntities(entities, ['code', 'contractNumber', 'tdlNumber'], search);
}

export function projectSort(a: Project, b: Project): number {
  return a.code.localeCompare(b.code);
}

export function purchaseOrderFilter(entities, search = '') {
  return filterEntities(
    entities,
    ['classification', 'number', 'projectCode', 'ripNumber', 'status', 'vendorName'],
    search,
  );
}
export function purchaseOrderSort(a: PurchaseOrderExtended, b: PurchaseOrderExtended): number {
  return a.number.toString().localeCompare(b.number.toString());
}

export function reportFilter(entities, search = '') {
  return filterEntities(entities, ['name', 'description'], search);
}

export function reportOrderSort(a: Report, b: Report): number {
  return a.name.toString().localeCompare(b.name.toString());
}
export function ripFilter(entities, search = '') {
  return filterEntities(entities, ['number'], search);
}

export function ripSelect(rip: Rip) {
  return rip.number;
}

export function ripSort(a: Rip, b: Rip): number {
  return a.number.localeCompare(b.number);
}

export function roomFilter(entities, search = '') {
  return filterEntities(entities, ['name'], search);
}

export function roomSort(a: Room, b: Room): number {
  return a.name.localeCompare(b.name);
}

export function shippingLocationFilter(entities, search = '') {
  return filterEntities(entities, ['name', 'address1', 'city', 'state', 'zip'], search);
}

export function shippingLocationSort(a: ShippingLocation, b: ShippingLocation) {
  return a.name.localeCompare(b.name);
}

export function shippingOrderFilter(entities, search = '') {
  return filterEntities(
    entities,
    [
      'notes',
      'number',
      'projectCode',
      'shippingLocationAddress1',
      'shippingLocationAddress2',
      'shippingLocationCity',
      'shippingLocationName',
      'shippingLocationState',
      'shippingLocationZip',
      'status',
    ],
    search,
  );
}

export function shippingOrderSort(a: ShippingOrderExtended, b: ShippingOrderExtended) {
  return a.number.toString().localeCompare(b.number.toString());
}
export function siteSort(a: Site, b: Site) {
  return a.name.toString().localeCompare(b.name.toString());
}

export function subCustodyAssignmentFilter(entities, search = '') {
  return filterEntities(entities, ['custodian.fistName', 'custodian.lastName', 'notes'], search);
}

export function subCustodyAssignmentSort(a: SubCustodyAssignment, b: SubCustodyAssignment): number {
  return a.assignmentDate.localeCompare(b.assignmentDate);
}

export function userFilter(entities, search = '') {
  return filterEntities(entities, ['firstName', 'lastName', 'username'], search);
}

export function userSort(a: User, b: User): number {
  return a.firstName.localeCompare(b.firstName);
}
export function vendorFilter(entities, search = '') {
  return filterEntities(entities, ['name'], search);
}

export function vendorSort(a: Vendor, b: Vendor): number {
  return a.name.localeCompare(b.name);
}

export function filterEntities(entities: any[], keys: string[], search = '') {
  if (!search) {
    // Break early if there is no search string
    return entities;
  }
  const lower = search.toLowerCase();
  return entities.filter(function (e) {
    let match = false;
    keys.forEach(function (key) {
      const val = _.get(e, key);
      if (!match && val) {
        if (_.isNumber(val)) {
          match = val.toString().includes(lower);
        } else if (_.isString(val)) {
          match = val.toLowerCase().includes(lower);
        } else {
          throw new Error('We have an issue');
        }
      }
    });
    return match;
  });
}
