<div class="float-right">
  <button
    (click)="openAddItemsToShippingOrderDialog()"
    class="btn btn-outline-primary btn-sm"
    [disabled]="loading$ | async"
  >
    <i class="far fa-layer-plus"></i> Add to Existing Shipping Order</button
  >&nbsp;
  <button (click)="openNewShippingOrderModal()" class="btn btn-outline-primary btn-sm" [disabled]="loading$ | async">
    <i class="far fa-plus"></i> New Shipping Order
  </button>
  &nbsp;
  <button
    (click)="openNewSubCustodyAssignmentModal()"
    class="btn btn-outline-primary btn-sm"
    [disabled]="loading$ | async"
  >
    <i class="far fa-plus"></i> New Sub-Custody Assignment
  </button>
  &nbsp;
  <button (click)="openUpdateLocationDialog()" class="btn btn-outline-primary btn-sm" [disabled]="loading$ | async">
    <i class="far fa-map-marker-alt"></i> Update Location for Items
  </button>
  &nbsp;
  <button (click)="openUpdateProjectDialog()" class="btn btn-outline-primary btn-sm" [disabled]="loading$ | async">
    <i class="far fa-layer-plus"></i> Update Project
  </button>
</div>
<h3><i class="far fa-shopping-cart"></i> Cart</h3>
<table class="table table-striped table-sm table-bordered">
  <thead>
    <tr>
      <th>Status</th>
      <th>SMX ID #</th>
      <th>NHA ID #</th>
      <th>Part Number</th>
      <th>Manufacturer</th>
      <th>Description</th>
      <th>Serial Number</th>
      <th>Project</th>
      <th class="d-print-none">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of itemsInCart$ | async">
      <td>{{ item.status }}</td>
      <td>{{ item.smxId }}</td>
      <td>{{ item.nhaSmxId }}</td>
      <td>{{ item.partNumber || 'n/a' }}</td>
      <td>{{ item.manufacturerName }}</td>
      <td>{{ item.partDescription }}</td>
      <td>{{ item.serialNumber }}</td>
      <td>{{ item.projectCode }}</td>
      <td class="d-print-none">
        <div class="no-wrap">
          <button
            (click)="openItemEditDialog(item.id)"
            title="Edit"
            type="button"
            [disabled]="loading$ | async"
            class="btn btn-outline-primary btn-sm no-wrap"
          >
            <i class="far fa-edit"></i>
          </button>
          &nbsp;
          <button
            (click)="removeFromCart(item.id)"
            title="Remove from Cart"
            type="button"
            class="btn btn-outline-warning btn-sm no-wrap"
            [disabled]="loading$ | async"
          >
            <i class="far fa-undo"></i>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</table>
