import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityServices } from '@ngrx/data';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Part } from 'src/app/models';
import { ManufacturersService } from 'src/app/services/manufacturers.service';
import { PartsService } from 'src/app/services/parts.service';

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styles: [],
})
export class PartsComponent implements OnInit {
  public filterValue$: Observable<string>;
  public gridData$: Observable<GridDataResult>;
  public loaded$: Observable<boolean>;
  public pageSize = 40;
  public rowHeight = 30;
  public skip$ = new BehaviorSubject<number>(0);
  public sort$ = new BehaviorSubject<SortDescriptor[]>([
    {
      field: 'partNumber',
      dir: 'asc',
    },
  ]);

  constructor(
    private entityServices: EntityServices,
    public dialog: MatDialog,
    private manufacturersService: ManufacturersService,
    private partsService: PartsService,
  ) {}

  ngOnInit() {
    this.loaded$ = this.partsService.loaded$;
    this.filterValue$ = this.partsService.filter$;

    this.gridData$ = combineLatest(this.partsService.filteredEntities$, this.sort$, this.skip$).pipe(
      map(([parts, [sort], skip]) => {
        const data = sort.dir === 'asc' ? _.sortBy(parts, sort.field) : _.sortBy(parts, sort.field).reverse();

        const pagedData = data.slice(skip, skip + this.pageSize);
        return { data: pagedData, total: data.length };
      }),
    );
  }
  openNewDialog() {
    this.partsService.openNewDialog().subscribe();
  }
  openEditDialog(part: Part) {
    this.partsService.openEditDialog(part).subscribe();
  }
  delete(part: Part) {
    this.partsService.delete(part);
  }
  filterParts(filter) {
    this.partsService.setFilter(filter);
  }
  public sortChange(sort: SortDescriptor[]) {
    this.sort$.next(sort);
  }

  public pageChange(event: PageChangeEvent) {
    this.skip$.next(event.skip);
  }
}
