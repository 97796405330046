<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>{{ data.title }}</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label for="name">Shipping Order Number</label>
        <input formControlName="number" class="form-control form-control-sm" />
        <p *ngIf="form.get('number').invalid && form.get('number').dirty" class="text-danger">
          A Shipping Order Number is required
        </p>
      </div>
      <div class="form-group col-sm-4">
        <label for="name">Site</label>
        <select formControlName="siteId" class="form-control form-control-sm">
          <option *ngFor="let site of data.sites" [ngValue]="site.id">{{ site.name }}</option>
        </select>
        <p *ngIf="form.get('siteId').invalid && form.get('siteId').dirty" class="text-danger">
          An originating SMARTTRONIX Site is required
        </p>
      </div>
      <div class="form-group col-sm-4">
        <label for="status">Status</label>
        <select formControlName="status" class="form-control form-control-sm">
          <option>Pending</option>
          <option>Shipped</option>
        </select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-6">
        <label for="projectId">Project</label>
        <select formControlName="projectId" class="form-control form-control-sm">
          <option *ngFor="let project of data.projects" [ngValue]="project.id">{{ project.code }}</option>
        </select>
        <p *ngIf="form.get('projectId').invalid && form.get('projectId').dirty" class="text-danger">
          A Project is required
        </p>
      </div>
      <div class="form-group col-sm-6">
        <label for="shippingDate">Shipping Date</label>
        <input formControlName="shippingDate" type="date" class="form-control form-control-sm" />
      </div>
    </div>

    <div class="form-group">
      <label for="shippingLocationId">Shipping Location</label>
      <a
        (click)="openNewShippingLocationModal()"
        style="margin-top: 2px;"
        class="float-right small pointer text-primary"
        ><i class="far fa-plus"></i> New Shipping Location</a
      >
      <select formControlName="shippingLocationId" class="form-control form-control-sm">
        <option *ngFor="let location of shippingLocations$ | async" [ngValue]="location.id">{{ location.name }}</option>
      </select>
      <p *ngIf="form.get('shippingLocationId').invalid && form.get('shippingLocationId').dirty" class="text-danger">
        A Shipping Location is required
      </p>
    </div>

    <div class="form-group">
      <label for="trackingNumber">Tracking Number</label>
      <input formControlName="trackingNumber" type="text" class="form-control form-control-sm" />
    </div>

    <div class="form-group">
      <label for="notes">Notes</label>
      <textarea autosize formControlName="notes" class="form-control form-control-sm"></textarea>
    </div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary float-right btn-sm">
      <fa-icon [icon]="faSave"></fa-icon> Save
    </button>
  </div>
</form>
