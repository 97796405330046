import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-403',
  templateUrl: './403.component.html',
  styleUrls: ['./403.component.scss'],
})
export class FourOThreeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
