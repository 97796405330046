import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubCustodyAssignment, Custodian } from 'src/app/models';
import { CustodiansService } from 'src/app/services/custodians.service';
import { Observable } from 'rxjs';
import { defaultDate, convertToUtcDates } from 'src/app/services/utility.service';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-sub-custody-assignment-dialog',
  templateUrl: './sub-custody-assignment-dialog.component.html',
  styles: [``],
})
export class SubCustodyAssignmentDialogComponent implements OnInit {
  public form: FormGroup;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<SubCustodyAssignmentDialogComponent>,
    private fb: FormBuilder,
    private custodiansService: CustodiansService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      subCustodyAssignment: SubCustodyAssignment;
      title: string;
      custodians$: Observable<Custodian[]>;
      currentUserCanEdit: boolean;
    },
  ) {}

  ngOnInit() {
    const formFields: { [key: string]: any } = {
      assignmentDate: [
        defaultDate(this.data.subCustodyAssignment.assignmentDate || new Date().toUTCString()),
        Validators.required,
      ],
      custodianId: [this.data.subCustodyAssignment.custodianId, Validators.required],
      notes: [this.data.subCustodyAssignment.notes],
    };
    if (this.data.subCustodyAssignment.id) {
      formFields.id = [this.data.subCustodyAssignment.id];
      formFields.createdBy = [this.data.subCustodyAssignment.createdBy];
      formFields.createdTime = [this.data.subCustodyAssignment.createdTime];
    }
    this.form = this.fb.group(formFields);
  }
  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    const formValue = convertToUtcDates(this.form.value, ['assignmentDate']);
    this.dialogRef.close(formValue);
  }
  openNewCustodianModal() {
    this.custodiansService.openNewDialog().subscribe((custodian) => {
      if (custodian?.id) {
        this.form.get('custodianId').setValue(custodian.id);
      }
    });
  }
}
