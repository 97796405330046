<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>Update Item Locations</h3>
  </div>

  <mat-dialog-content class="modal-dialog-content">
    <fieldset>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label for="name">Site</label>
          <select formControlName="siteId" class="form-control form-control-sm">
            <option *ngFor="let site of data.sites" [ngValue]="site.id">{{ site.name }}</option>
          </select>
          <p *ngIf="form.get('siteId').invalid && form.get('siteId').dirty" class="text-danger">
            An SMARTTRONIX Site is required
          </p>
        </div>
        <div class="form-group col-sm-4">
          <label for="buildingId">Building</label>
          <a (click)="openNewBuildingModal()" style="margin-top: 2px;" class="float-right small pointer text-primary"
            ><i class="far fa-plus"></i> New Building</a
          >
          <select formControlName="buildingId" class="form-control form-control-sm">
            <option [ngValue]="null"></option>
            <option *ngFor="let building of buildings$ | async" [ngValue]="building.id">{{ building.name }}</option>
          </select>
        </div>
        <div class="form-group col-sm-4">
          <label for="roomId">Room</label>
          <a (click)="openNewRoomsModal()" style="margin-top: 2px;" class="float-right small pointer text-primary"
            ><i class="far fa-plus"></i> New Room</a
          >
          <select formControlName="roomId" class="form-control form-control-sm">
            <option [ngValue]="null"></option>
            <option *ngFor="let room of rooms$ | async" [ngValue]="room.id">{{ room.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label for="roomId">Location</label>
          <input formControlName="location" type="text" class="form-control form-control-sm" />
        </div>
      </div>
      <div class="form-row">
        <label for="assignedToId">Assigned To</label>
        <select formControlName="assignedToId" class="form-control form-control-sm">
          <option [ngValue]="null"></option>
          <option *ngFor="let custodian of data.custodians" [ngValue]="custodian.id">
            {{ custodian.firstName }} {{ custodian.lastName }}
          </option>
        </select>
      </div>
    </fieldset>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <i class="far fa-times"></i> Cancel
    </button>
    <div class="float-right">
      <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary btn-sm">
        <fa-icon [icon]="faSave"></fa-icon> Save
      </button>
    </div>
  </div>
</form>
