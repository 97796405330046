import { NgModule } from '@angular/core';
import { DefaultDataServiceConfig, EntityDataModule, EntityServices, HttpUrlGenerator } from '@ngrx/data';
import { BuildingsService } from '../services/buildings.service';
import { CustodiansService } from '../services/custodians.service';
import { ItemsService } from '../services/items.service';
import { ManufacturersService } from '../services/manufacturers.service';
import { PartsService } from '../services/parts.service';
import { ProjectsService } from '../services/projects.service';
import { PurchaseOrdersService } from '../services/purchase-orders.service';
import { ReportsService } from '../services/reports.service';
import { RipsService } from '../services/rips.service';
import { RoomsService } from '../services/rooms.service';
import { ShippingLocationsService } from '../services/shipping-locations.service';
import { ShippingOrdersService } from '../services/shipping-orders.service';
import { SitesService } from '../services/sites.service';
import { SubCustodyAssignmentsService } from '../services/sub-custody-assignments.service';
import { UsersService } from '../services/users.service';
import { VendorsService } from '../services/vendors.service';
import { CustomHttpUrlGenerator } from './custom-http-url-generator';
import { defaultDataServiceConfig } from './default-data-service.config';
import { entityConfig } from './entity-metadata';

@NgModule({
  imports: [EntityDataModule.forRoot(entityConfig)],
  providers: [
    BuildingsService,
    CustodiansService,
    ItemsService,
    ManufacturersService,
    PartsService,
    ProjectsService,
    PurchaseOrdersService,
    ReportsService,
    RipsService,
    RoomsService,
    ShippingLocationsService,
    ShippingOrdersService,
    SitesService,
    SubCustodyAssignmentsService,
    UsersService,
    VendorsService,
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    { provide: HttpUrlGenerator, useClass: CustomHttpUrlGenerator },
  ],
})
export class EntityStoreModule {
  constructor(
    buildingsService: BuildingsService,
    custodiansService: CustodiansService,
    entityServices: EntityServices,
    itemsService: ItemsService,
    manufacturersService: ManufacturersService,
    partsService: PartsService,
    projectsService: ProjectsService,
    purchaseOrdersService: PurchaseOrdersService,
    reportsService: ReportsService,
    ripsService: RipsService,
    roomsService: RoomsService,
    shippingLocationsService: ShippingLocationsService,
    shippingOrdersService: ShippingOrdersService,
    sitesService: SitesService,
    subCustodyAssignmentsService: SubCustodyAssignmentsService,
    usersService: UsersService,
    vendorsService: VendorsService,
  ) {
    entityServices.registerEntityCollectionServices([
      buildingsService,
      custodiansService,
      itemsService,
      manufacturersService,
      partsService,
      projectsService,
      purchaseOrdersService,
      reportsService,
      ripsService,
      roomsService,
      shippingLocationsService,
      shippingOrdersService,
      sitesService,
      subCustodyAssignmentsService,
      usersService,
      vendorsService,
    ]);
  }
}
