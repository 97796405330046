import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Custodian } from 'src/app/models';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-custodian-dialog',
  templateUrl: './custodian-dialog.component.html',
  styles: [``],
})
export class CustodianDialogComponent implements OnInit {
  public form: FormGroup;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<CustodianDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { custodian: Custodian; title: string; custodians: Custodian[] },
  ) {}

  ngOnInit() {
    const formFields: { [key: string]: any } = {
      firstName: [this.data.custodian.firstName, Validators.required],
      lastName: [this.data.custodian.lastName, Validators.required],
      emailAddress: [this.data.custodian.emailAddress],
      phoneNumber: [this.data.custodian.phoneNumber],
      notes: [this.data.custodian.notes],
    };
    if (this.data.custodian.id) {
      formFields.id = [this.data.custodian.id];
      formFields.createdBy = [this.data.custodian.createdBy];
      formFields.createdTime = [this.data.custodian.createdTime];
    }
    this.form = this.fb.group(formFields);
  }
  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.form.value);
  }
}
