import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, combineLatest } from 'rxjs';
import { filter, first, takeUntil, tap, switchMap } from 'rxjs/operators';
import { ApplicationInsightsService } from './services/application-insights.service';
import { BuildingsService } from './services/buildings.service';
import { CustodiansService } from './services/custodians.service';
import { ItemsService } from './services/items.service';
import { ManufacturersService } from './services/manufacturers.service';
import { PartsService } from './services/parts.service';
import { ProjectsService } from './services/projects.service';
import { PurchaseOrdersService } from './services/purchase-orders.service';
import { ReportsService } from './services/reports.service';
import { RipsService } from './services/rips.service';
import { RoomsService } from './services/rooms.service';
import { ShippingLocationsService } from './services/shipping-locations.service';
import { ShippingOrdersService } from './services/shipping-orders.service';
import { SitesService } from './services/sites.service';
import { SubCustodyAssignmentsService } from './services/sub-custody-assignments.service';
import { UsersService } from './services/users.service';
import { VendorsService } from './services/vendors.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private applicationInsightsService: ApplicationInsightsService,
    private buildingsService: BuildingsService,
    private custodiansService: CustodiansService,
    private itemsService: ItemsService,
    private manufacturersService: ManufacturersService,
    private partsService: PartsService,
    private projectsService: ProjectsService,
    private purchaseOrdersService: PurchaseOrdersService,
    private reportsService: ReportsService,
    private ripsService: RipsService,
    private roomsService: RoomsService,
    private shippingLocationsService: ShippingLocationsService,
    private shippingOrdersService: ShippingOrdersService,
    private sitesService: SitesService,
    private subCustodyAssignmentsService: SubCustodyAssignmentsService,
    private usersService: UsersService,
    private vendorsService: VendorsService,
    private msalBroadcastService: MsalBroadcastService,
  ) {}
  ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.fetchData();
      });
  }
  fetchData() {
    this.usersService.currentUser$
      .pipe(
        filter((currentUser) => !!currentUser),
        first(),
        tap((currentUser) => {
          this.applicationInsightsService.setUserId(currentUser.id.toString());
        }),
        switchMap((currentUser) => {
          // Load the most important data first
          return combineLatest([
            this.itemsService.getEntities(true),
            this.sitesService.getAll(),
            this.usersService.getAll(),
          ]);
        }),
      )
      .subscribe(() => {
        // Load data that we don't need immediately
        this.purchaseOrdersService.getAll().subscribe();
        this.shippingOrdersService.getAll().subscribe();
        this.subCustodyAssignmentsService.getAll().subscribe();
        this.reportsService.getAll().subscribe();
        this.buildingsService.getAll().subscribe();
        this.custodiansService.getAll().subscribe();
        this.manufacturersService.getAll().subscribe();
        this.projectsService.getAll().subscribe();
        this.ripsService.getAll().subscribe();
        this.roomsService.getAll().subscribe();
        this.shippingLocationsService.getAll().subscribe();
        this.vendorsService.getAll().subscribe();
        this.partsService.getAll().subscribe();
      });
    this.usersService.authenticate().subscribe();
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
