<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>{{ data.title }}</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <div class="form-group">
      <label for="name">Name</label>
      <input formControlName="name" class="form-control form-control-sm" />
      <p *ngIf="form.get('name').invalid && form.get('name').dirty" class="text-danger">
        A Name for this location is required
      </p>
    </div>
    <div class="form-group">
      <label for="address1">Address 1</label>
      <input formControlName="address1" class="form-control form-control-sm" />
      <p *ngIf="form.get('address1').invalid && form.get('address1').dirty" class="text-danger">
        A address is required
      </p>
    </div>
    <div class="form-group">
      <label for="address2">Address 2</label>
      <input formControlName="address2" class="form-control form-control-sm" />
      <p *ngIf="form.get('address2').invalid && form.get('address2').dirty" class="text-danger">
        A address is required
      </p>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-6">
        <label for="address2">City</label>
        <input formControlName="city" class="form-control form-control-sm" />
        <p *ngIf="form.get('city').invalid && form.get('city').dirty" class="text-danger">
          A city is required
        </p>
      </div>
      <div class="form-group col-sm-3">
        <label for="state">State</label>
        <input formControlName="state" class="form-control form-control-sm" />
        <p *ngIf="form.get('state').invalid && form.get('state').dirty" class="text-danger">
          A State is required
        </p>
      </div>
      <div class="form-group col-sm-3">
        <label for="zip">Zip Code</label>
        <input formControlName="zip" class="form-control form-control-sm" />
        <p *ngIf="form.get('zip').invalid && form.get('zip').dirty" class="text-danger">
          A Zip Code is required
        </p>
      </div>
    </div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary float-right btn-sm">
      <fa-icon [icon]="faSave"></fa-icon> Save
    </button>
  </div>
</form>
