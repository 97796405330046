import { EntityMetadataMap } from '@ngrx/data';
import {
  buildingFilter,
  buildingSort,
  custodianFilter,
  custodianSort,
  itemFilter,
  itemSort,
  manufacturerFilter,
  manufacturerSort,
  partFilter,
  partSort,
  projectFilter,
  projectSort,
  purchaseOrderFilter,
  purchaseOrderSort,
  reportFilter,
  reportOrderSort,
  ripFilter,
  ripSelect,
  ripSort,
  roomFilter,
  roomSort,
  shippingLocationFilter,
  shippingLocationSort,
  shippingOrderFilter,
  shippingOrderSort,
  siteSort,
  subCustodyAssignmentFilter,
  subCustodyAssignmentSort,
  userFilter,
  userSort,
  vendorFilter,
  vendorSort,
} from './entity-function';

const entityMetadata: EntityMetadataMap = {
  Building: {
    filterFn: buildingFilter,
    sortComparer: buildingSort,
  },
  Custodian: {
    filterFn: custodianFilter,
    sortComparer: custodianSort,
  },
  Item: {
    filterFn: itemFilter,
    sortComparer: itemSort,
  },
  Manufacturer: {
    filterFn: manufacturerFilter,
    sortComparer: manufacturerSort,
  },
  Part: {
    filterFn: partFilter,
    sortComparer: partSort,
  },
  Project: {
    filterFn: projectFilter,
    sortComparer: projectSort,
  },
  PurchaseOrder: {
    filterFn: purchaseOrderFilter,
    sortComparer: purchaseOrderSort,
  },
  PurchaseOrderExtended: {
    filterFn: purchaseOrderFilter,
    sortComparer: purchaseOrderSort,
  },
  Report: {
    filterFn: reportFilter,
    sortComparer: reportOrderSort,
  },
  Rip: {
    filterFn: ripFilter,
    selectId: ripSelect,
    sortComparer: ripSort,
  },
  Room: {
    filterFn: roomFilter,
    sortComparer: roomSort,
  },
  ShippingLocation: {
    filterFn: shippingLocationFilter,
    sortComparer: shippingLocationSort,
  },
  ShippingOrder: {
    filterFn: shippingOrderFilter,
    sortComparer: shippingOrderSort,
  },
  Site: {
    sortComparer: siteSort,
  },
  SubCustodyAssignment: {
    filterFn: subCustodyAssignmentFilter,
    sortComparer: subCustodyAssignmentSort,
  },
  User: {
    filterFn: userFilter,
    sortComparer: userSort,
  },
  Vendor: {
    filterFn: vendorFilter,
    sortComparer: vendorSort,
  },
};

const pluralNames = {};

export const entityConfig = {
  entityMetadata,
  pluralNames,
};
