import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User, Site } from 'src/app/models';
import { SitesService } from 'src/app/services/sites.service';
import { Observable } from 'rxjs';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';
import { ApplicationRole } from 'src/app/enums';
import { APPLICATION_ROLES } from 'src/app/consts';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styles: [``],
})
export class UserDialogComponent implements OnInit {
  public form: FormGroup;
  public applicationRoles: ApplicationRole[];
  public sites$: Observable<Site[]>;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    private fb: FormBuilder,
    private sitesService: SitesService,
    @Inject(MAT_DIALOG_DATA) public data: { user: User; title: string; users: User[] },
  ) {}

  ngOnInit() {
    this.sites$ = this.sitesService.entities$;
    const formFields: { [key: string]: any } = {
      firstName: [{ value: this.data.user.firstName, disabled: true }, Validators.required],
      lastName: [{ value: this.data.user.lastName, disabled: true }, Validators.required],
      registrationDate: [this.data.user.registrationDate],
      role: [this.data.user.role],
      siteIds: [this.data.user.siteIds, Validators.minLength(1)],
      username: [{ value: this.data.user.username, disabled: true }, Validators.required],
    };

    this.form = this.fb.group(formFields);
    this.applicationRoles = APPLICATION_ROLES;
  }
  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close({
      ...this.form.value,
      // Disabled values won't be returned from form so manually add them in
      id: this.data.user.id,
      createdBy: this.data.user.createdBy,
      createdTime: this.data.user.createdTime,
      firstName: this.data.user.firstName,
      lastName: this.data.user.lastName,
      registrationDate: this.data.user.registrationDate,
      username: this.data.user.username,
    });
  }
}
