<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>Add to Shipping Order</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <div class="form-group row">
      <label class="col-sm-5" for="shippingOrderId">Shipping Order</label>
      <div class="col-sm-7">
        <select formControlName="shippingOrderId" class="form-control">
          <option *ngFor="let shippingOrder of data.shippingOrders" [ngValue]="shippingOrder.id">{{
            shippingOrder.number
          }}</option>
        </select>
        <p *ngIf="form.get('shippingOrderId').invalid && form.get('shippingOrderId').dirty" class="text-danger">
          A Shipping Order is required
        </p>
      </div>
    </div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary float-right btn-sm">
      <fa-icon [icon]="faSave"></fa-icon> Save
    </button>
  </div>
</form>
