import { ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromCart from './cart.reducer';

export interface State {
  cart: fromCart.State;
}

export const reducers: ActionReducerMap<State> = {
  cart: fromCart.counterReducer,
};

export const selectCart = (state: State) => state.cart;

export const selectCartIds = createSelector(
  selectCart,
  state => state.ids,
);
