<div class="container" *ngIf="subCustodyAssignment">
  <div data-size="A4">
    <div class="float-right po-header-controls d-print-none" *ngIf="currentUserCanEdit">
      <button
        (click)="deleteSubCustodyAssignment.next()"
        *ngIf="displayDeleteButton"
        type="button"
        class="btn btn-outline-danger btn-sm"
      >
        <i class="far fa-trash"></i> Delete Sub-Custody Assignment
      </button>
      <button
        (click)="openSubCustodyAssignmentEditDialog.next(subCustodyAssignment)"
        type="button"
        class="btn btn-outline-primary btn-sm"
      >
        <i class="far fa-edit"></i> Edit Sub-Custody Assignment
      </button>
      <!-- <button (click)="openItemNewDialog.next()" type="button" class="btn btn-outline-primary btn-sm">
        <i class="far fa-plus"></i> Add New Item
      </button> -->
    </div>
    <h3>
      Sub-Custody Assignment ({{ subCustodyAssignment.custodian.firstName }}
      {{ subCustodyAssignment.custodian.lastName }} - {{ subCustodyAssignment.assignmentDate | date }})
    </h3>
    <div class="row po-header-row">
      <!-- <div *ngIf="subCustodyAssignment.project" class="col-sm-6 po-details">
        <span class="label">Project</span>
        {{ subCustodyAssignment.project.code }} {{ subCustodyAssignment.project.title }} <br />

        <span class="label">Shipping Date</span>
        {{ (subCustodyAssignment.shippingDate | date) || 'N/A' }}
      </div>
      <div class="col-sm-6 po-details" *ngIf="subCustodyAssignment.shippingLocation">
        <span class="label">Address</span><br />
        {{ subCustodyAssignment.shippingLocation.address1 }} <br />
        <ng-container *ngIf="subCustodyAssignment.shippingLocation.address2">
          {{ subCustodyAssignment.shippingLocation.address2 }} <br />
        </ng-container>
        {{ subCustodyAssignment.shippingLocation.city }} {{ subCustodyAssignment.shippingLocation.state }},
        {{ subCustodyAssignment.shippingLocation.zip }}
      </div> -->
      <!-- <div class="col-sm-3 po-details">
        <span class="label">RIP</span>
        {{ subCustodyAssignment.ripNumber }}
      </div> -->
    </div>
    <div class="po-header-row">
      <div class="po-details">
        <span class="label">Notes</span>
        {{ subCustodyAssignment.notes }}
      </div>
    </div>

    <table class="table table-striped table-sm table-bordered">
      <thead>
        <tr>
          <th>Part Number</th>
          <th>Manufacturer</th>
          <th>Description</th>
          <th>Serial Number</th>
          <!-- <th>Unit Price</th>
          <th>Total Cost</th> -->
          <th>Quantity</th>
          <th class="d-print-none">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items">
          <td>{{ item.part?.partNumber || 'n/a' }}</td>
          <td>{{ item.part?.manufacturer.name }}</td>
          <td>{{ item.part?.description }}</td>
          <td>{{ item.serialNumber }}</td>
          <!-- <td>{{ item.unitPrice | currency }}</td>
          <td>{{ item.totalCost | currency }}</td> -->
          <td>{{ item.orderQuantity }}</td>
          <td class="d-print-none">
            <div class="no-wrap">
              <button
                (click)="openItemEditDialog.next(item)"
                title="Edit"
                type="button"
                class="btn btn-outline-primary btn-sm no-wrap"
              >
                <i class="far fa-edit"></i>
              </button>
              <ng-container *ngIf="currentUserCanEdit">
                &nbsp;
                <button
                  (click)="removeItem.next(item)"
                  title="Delete"
                  type="button"
                  class="btn btn-outline-danger btn-sm no-wrap"
                >
                  <i class="far fa-trash-alt"></i>
                </button>
              </ng-container>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
