import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { CustodianDialogComponent } from '../components/custodians/custodian-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { Custodian } from '../models';

@Injectable()
export class CustodiansService extends EntityCollectionServiceBase<Custodian> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, public dialog: MatDialog) {
    super('Custodian', serviceElementsFactory);
  }
  delete(entity: string | number | Custodian, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  openNewDialog(): Observable<Custodian> {
    return this.entities$.pipe(
      first(),
      switchMap((custodians) => {
        const dialogRef = this.dialog.open(CustodianDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { custodian: { number: null }, title: 'Create New Custodian', custodians },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  openEditDialog(custodian: Custodian): Observable<Custodian> {
    return this.entities$.pipe(
      first(),
      switchMap((custodians) => {
        const dialogRef = this.dialog.open(CustodianDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { custodian, title: 'Edit Custodian', custodians },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
}
