import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { first, switchMap, tap, map } from 'rxjs/operators';
import { PartDialogComponent } from '../components/parts/part-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { Part } from '../models';
import { ManufacturersService } from './manufacturers.service';

@Injectable({ providedIn: 'root' })
export class PartsService extends EntityCollectionServiceBase<Part> {
  public conditionCodeOptions = ['A', 'B', 'C'];
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, public dialog: MatDialog) {
    super('Part', serviceElementsFactory);
  }
  delete(entity: string | number | Part, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  // getAll() {
  //   const query = super.getWithQuery({
  //     $expand: defaultExpansion,
  //   });
  //   query.pipe(tap(() => this.setLoaded(true))).subscribe();
  //   return query;
  // }
  add(entity: Part, options?: EntityActionOptions) {
    return super.add(entity, options);
    // .pipe(
    //   switchMap((e) =>
    //     this.getWithQuery({
    //       $filter: `id eq ${e.id}`,
    //       $expand: defaultExpansion,
    //     }),
    //   ),
    //   map(([e]) => e),
    // );
  }
  update(entity: Part, options?: EntityActionOptions) {
    return super.update(entity, options);
    // .pipe(
    //   switchMap((e) =>
    //     this.getWithQuery({
    //       $filter: `id eq ${e.id}`,
    //       $expand: defaultExpansion,
    //     }),
    //   ),
    //   map(([e]) => e),
    // );
  }
  openNewDialog(): Observable<Part> {
    const dialogRef = this.dialog.open(PartDialogComponent, {
      minWidth: '50%',
      disableClose: true,
      data: {
        title: 'Create New Part',
        part: {
          description: null,
          manufacturerId: null,
          partNumber: null,
          unitOfIssue: 'EA',
        },
        conditionCodeOptions: this.conditionCodeOptions,
      },
    });

    return dialogRef.afterClosed().pipe(
      first(),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  openEditDialog(part: Part): Observable<Part> {
    const dialogRef = this.dialog.open(PartDialogComponent, {
      minWidth: '50%',
      disableClose: true,
      data: {
        part,
        title: 'Update Part',
        conditionCodeOptions: this.conditionCodeOptions,
      },
    });

    return dialogRef.afterClosed().pipe(
      first(),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
}
