import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { VendorDialogComponent } from '../components/vendors/vendor-dialog.component';
import { Vendor } from '../models';

@Injectable({ providedIn: 'root' })
export class VendorsService extends EntityCollectionServiceBase<Vendor> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, public dialog: MatDialog) {
    super('Vendor', serviceElementsFactory);
  }
  delete(entity: string | number | Vendor, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  openNewDialog(): Observable<Vendor> {
    return this.entities$.pipe(
      first(),
      switchMap((vendors: Vendor[]) => {
        const dialogRef = this.dialog.open(VendorDialogComponent, {
          disableClose: true,
          minWidth: '50%',
          data: { vendor: { name: null }, title: 'Create New Vendor', vendors },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  openEditDialog(vendor: Vendor): Observable<Vendor> {
    return this.entities$.pipe(
      first(),
      switchMap((vendors: Vendor[]) => {
        const dialogRef = this.dialog.open(VendorDialogComponent, {
          disableClose: true,
          minWidth: '50%',
          data: { vendor, title: 'Edit Vendor', vendors },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
}
