import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ItemExtended, SubCustodyAssignment } from 'src/app/models';
import { ItemsService } from 'src/app/services/items.service';
import { SubCustodyAssignmentsService } from 'src/app/services/sub-custody-assignments.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-sub-custody-assignment-container-details',
  template: `
    <app-sub-custody-assignment-details
      *ngIf="loaded$ | async; else loading"
      [items]="items$ | async"
      [currentUserCanEdit]="currentUserCanEdit$ | async"
      [subCustodyAssignment]="subCustodyAssignment$ | async"
      [displayDeleteButton]="displayDeleteButton$ | async"
      (removeItem)="removeItem($event)"
      (openCloneItemDialog)="openCloneItemDialog($event)"
      (openItemNewDialog)="openItemNewDialog($event)"
      (openItemEditDialog)="openItemEditDialog($event)"
      (openSubCustodyAssignmentEditDialog)="openSubCustodyAssignmentEditDialog($event)"
      (deleteSubCustodyAssignment)="deleteSubCustodyAssignment($event)"
    >
    </app-sub-custody-assignment-details>

    <ng-template #loading>
      <div class="loading-spinner"></div>
    </ng-template>
  `,
  styles: [],
})
export class SubCustodyAssignmentDetailsContainerComponent implements OnInit {
  public currentUserCanEdit$: Observable<boolean>;
  public displayDeleteButton$: Observable<boolean>;
  public loaded$: Observable<boolean>;
  public subCustodyAssignment$: Observable<SubCustodyAssignment>;
  public items$: Observable<ItemExtended[]>;
  public subCustodyAssignmentId: number;
  constructor(
    public dialog: MatDialog,
    public subCustodyAssignmentsService: SubCustodyAssignmentsService,
    private itemsService: ItemsService,
    private route: ActivatedRoute,
    private userService: UsersService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.subCustodyAssignmentId = Number(this.route.snapshot.params.id);
    this.itemsService.getBySubCustodyAssignmentId(this.subCustodyAssignmentId).subscribe();
    this.subCustodyAssignmentsService.getById(this.subCustodyAssignmentId).subscribe();
    this.loaded$ = this.subCustodyAssignmentsService.loaded$;
    this.currentUserCanEdit$ = this.userService.currentUserCanEdit$;

    this.items$ = this.itemsService.entities$.pipe(
      map((items) => items.filter((item) => item.subCustodyAssignmentId === this.subCustodyAssignmentId)),
      map((items) => _.sortBy(items, 'id')),
    );
    this.subCustodyAssignment$ = this.subCustodyAssignmentsService.entityMap$.pipe(
      map((subCustodyAssignmentDictionary) => subCustodyAssignmentDictionary[this.subCustodyAssignmentId]),
      filter((subCustodyAssignment) => !!subCustodyAssignment),
    );
    this.displayDeleteButton$ = combineLatest([this.items$, this.userService.currentUserIsAdmin$]).pipe(
      map(([items, currentUserIsAdmin]) => currentUserIsAdmin && items.length === 0),
    );
  }
  deleteSubCustodyAssignment() {
    this.subCustodyAssignmentsService.delete(this.subCustodyAssignmentId).subscribe(() => {
      this.router.navigate(['/sub-custody-assignments']);
    });
  }

  removeItem(item: ItemExtended) {
    const cleanedUpItem = { ...item, subCustodyAssignmentId: null, assignedToId: null };
    this.itemsService.update(cleanedUpItem).subscribe();
  }
  openCloneItemDialog(item: ItemExtended) {
    this.itemsService.openNewDialog(item).subscribe();
  }
  openItemNewDialog() {
    this.itemsService.openNewDialog({ subCustodyAssignmentId: this.subCustodyAssignmentId }).subscribe();
  }
  openItemEditDialog(item: ItemExtended) {
    this.itemsService.openEditDialog(item.id).subscribe();
  }
  openSubCustodyAssignmentEditDialog(subCustodyAssignment: SubCustodyAssignment) {
    this.subCustodyAssignmentsService.openEditDialog(subCustodyAssignment).subscribe();
  }
}
