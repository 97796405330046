import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ReportDialogComponent } from '../components/reports/report-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { Report } from '../models';

@Injectable()
export class ReportsService extends EntityCollectionServiceBase<Report> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, public dialog: MatDialog) {
    super('Report', serviceElementsFactory);
  }
  delete(entity: string | number | Report, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  openNewDialog(): Observable<Report> {
    return this.entities$.pipe(
      first(),
      switchMap((reports) => {
        const dialogRef = this.dialog.open(ReportDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { report: { name: null }, title: 'Create New Report', reports },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  openEditDialog(report: Report): Observable<Report> {
    return this.entities$.pipe(
      first(),
      switchMap((reports) => {
        const dialogRef = this.dialog.open(ReportDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { report, title: 'Edit Report', reports },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
}
