import { RouterModule, Routes } from '@angular/router';
import { BuildingsComponent } from './components/buildings/buildings.component';
import { CartComponent } from './components/cart/cart.component';
import { ItemsComponent } from './components/items/items.component';
import { ManufacturersComponent } from './components/manufacturers/manufacturers.component';
import { PartsComponent } from './components/parts/parts.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { PurchaseOrderDetailsContainerComponent } from './components/purchase-order-details/purchase-order-details-container.component';
import { PurchaseOrdersComponent } from './components/purchase-orders/purchase-orders.component';
import { RipsComponent } from './components/rips/rips.component';
import { RoomsComponent } from './components/rooms/rooms.component';
import { ShippingLocationsComponent } from './components/shipping-locations/shipping-locations.component';
import { ShippingOrderDetailsContainerComponent } from './components/shipping-order-details/shipping-order-details-container.component';
import { ShippingOrdersComponent } from './components/shipping-orders/shipping-orders.component';
import { UsersComponent } from './components/users/users.component';
import { VendorsComponent } from './components/vendors/vendors.component';
import { AdminGuard } from './helpers/admin.guard';
import { CustodiansComponent } from './components/custodians/custodians.component';
import { SubCustodyAssignmentsComponent } from './components/sub-custody-assignments/sub-custody-assignments.component';
import { SubCustodyAssignmentDetailsContainerComponent } from './components/sub-custody-assignment-details/sub-custody-assignment-details-container.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportComponent } from './components/reports/report.component';
import { MsalGuard } from '@azure/msal-angular';
import { UserGuard } from './helpers/user.guard';
import { FourOThreeComponent } from './components/403/403.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [MsalGuard, UserGuard],
    children: [
      { path: '', component: ItemsComponent, pathMatch: 'full' },
      { path: 'buildings', component: BuildingsComponent },
      { path: 'cart', component: CartComponent },
      { path: 'custodians', component: CustodiansComponent },
      { path: 'manufacturers', component: ManufacturersComponent },
      { path: 'parts', component: PartsComponent },
      { path: 'projects', component: ProjectsComponent },
      {
        path: 'receiving-document',
        children: [{ path: ':id', component: PurchaseOrderDetailsContainerComponent }],
      },
      { path: 'receiving-documents', component: PurchaseOrdersComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'reports/:id', component: ReportComponent },
      { path: 'rips', component: RipsComponent },
      { path: 'rooms', component: RoomsComponent },
      { path: 'shipping-locations', component: ShippingLocationsComponent },
      {
        path: 'shipping-order',
        children: [{ path: ':id', component: ShippingOrderDetailsContainerComponent }],
      },
      { path: 'shipping-orders', component: ShippingOrdersComponent },
      {
        path: 'sub-custody-assignment',
        children: [{ path: ':id', component: SubCustodyAssignmentDetailsContainerComponent }],
      },
      { path: 'sub-custody-assignments', component: SubCustodyAssignmentsComponent },
      { path: 'users', component: UsersComponent, canActivate: [AdminGuard] },
      { path: 'vendors', component: VendorsComponent },
    ],
  },
  {
    path: '403',
    component: FourOThreeComponent,
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

export const appRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
