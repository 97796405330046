import { AfterContentChecked, Directive, ElementRef, HostListener } from '@angular/core';

/**
 * This directive allows textarea inputs to automatically grow vertically
 * based on the content within.
 *
 * Originally taken from https://github.com/stevepapa/angular2-autosize but
 * wasn't maintained and couldn't get it to import correctly.
 */
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'textarea[autosize]',
})
export class AutosizeDirective implements AfterContentChecked {
    private scrollHeight = 0;
    @HostListener('input', ['$event.target'])
    onInput(textArea: HTMLTextAreaElement): void {
        this.adjust();
    }
    constructor(public element: ElementRef) {}
    ngAfterContentChecked(): void {
        if (this.element.nativeElement.scrollHeight !== this.scrollHeight) {
            this.adjust();
        }
    }
    adjust(): void {
        this.element.nativeElement.style.overflow = 'hidden';
        this.element.nativeElement.style.height = 'auto';
        // We only want to set height if we have a valid scrollHeight
        if (this.element.nativeElement.scrollHeight > 0) {
            this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight + 'px';
            this.scrollHeight = this.element.nativeElement.scrollHeight;
        }
    }
}
