import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { RipDialogComponent } from '../components/rips/rip-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { Rip } from '../models';

@Injectable({ providedIn: 'root' })
export class RipsService extends EntityCollectionServiceBase<Rip> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, public dialog: MatDialog) {
    super('Rip', serviceElementsFactory);
  }
  delete(entity: string | number | Rip, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  openNewDialog(): Observable<Rip> {
    return this.entities$.pipe(
      first(),
      switchMap((rips: Rip[]) => {
        const dialogRef = this.dialog.open(RipDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { rip: { number: null }, title: 'Create New Rip', rips },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  // openEditDialog(rip: Rip): Observable<Rip> {
  //   return this.entities$.pipe(
  //     first(),
  //     switchMap((rips: Rip[]) => {
  //       const dialogRef = this.dialog.open(RipDialogComponent, {
  //         minWidth: '50%',
  //         disableClose: true,
  //         data: { rip, title: 'Edit Rip', rips },
  //       });

  //       return dialogRef.afterClosed();
  //     }),
  //     switchMap((result) => {
  //       if (result) {
  //         return this.update(result);
  //       } else {
  //         return of(result);
  //       }
  //     }),
  //   );
  // }
}
