<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark bg-primary border-bottom box-shadow mb-3">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/']" style="text-decoration: none;">
        <i class="far fa-barcode-read"></i> Diogenes</a
      >
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target=".navbar-collapse"
        aria-label="Toggle navigation"
        [attr.aria-expanded]="isExpanded"
        (click)="toggle()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item" [routerLinkActive]="['active']">
            <a title="View all Receiving Documents" class="nav-link" [routerLink]="['/receiving-documents']">
              <i class="far fa-file-invoice-dollar"></i>&nbsp;
              <span class="d-none d-md-inline">Receiving Documents</span>
              <span class="d-md-none">RD's</span>
            </a>
          </li>

          <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
            <a title="View all Items" class="nav-link" [routerLink]="['/']"> <i class="fab fa-ethereum"></i> Items</a>
          </li>

          <li class="nav-item" [routerLinkActive]="['active']">
            <a title="View all Shipping Orders" class="nav-link" [routerLink]="['/shipping-orders']">
              <i class="far fa-shipping-fast"></i>&nbsp;
              <span class="d-none d-md-inline">Shipping Orders</span>
              <span class="d-md-none">PO's</span>
            </a>
          </li>

          <li class="nav-item dropdown" dropdown *ngIf="currentUserIsAdmin$ | async">
            <a class="nav-link pointer" dropdownToggle role="button">
              <i class="far fa-table"></i> Manage <i class="far fa-caret-down"></i>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right" placement="bottom right">
              <a class="dropdown-item" [routerLink]="['/buildings']"> <i class="far fa-warehouse"></i> Buildings</a>
              <a class="dropdown-item" [routerLink]="['/custodians']"> <i class="far fa-user-tag"></i> Custodians</a>
              <a class="dropdown-item" [routerLink]="['/manufacturers']">
                <i class="far fa-building"></i> Manufacturers</a
              >
              <a class="dropdown-item" [routerLink]="['/parts']"> <i class="far fa-wrench"></i> Parts</a>
              <a class="dropdown-item" [routerLink]="['/projects']"> <i class="far fa-book"></i> Projects</a>
              <a class="dropdown-item" [routerLink]="['/reports']"> <i class="far fa-chart-pie"></i> Reports</a>
              <a class="dropdown-item" [routerLink]="['/rips']"> <i class="far fa-hashtag"></i> RIPs</a>
              <a class="dropdown-item" [routerLink]="['/rooms']"> <i class="far fa-books"></i> Rooms</a>
              <a class="dropdown-item" [routerLink]="['/shipping-locations']">
                <i class="far fa-map-marked"></i> Shipping Locations</a
              >
              <a class="dropdown-item" [routerLink]="['/sub-custody-assignments']">
                <i class="far fa-person-carry"></i> Sub-Custody Assignments</a
              >
              <a class="dropdown-item" [routerLink]="['/users']" *ngIf="currentUserIsAppAdmin$ | async">
                <i class="far fa-users"></i> Users</a
              >
              <a class="dropdown-item" [routerLink]="['/vendors']"> <i class="far fa-cash-register"></i> Vendors</a>
            </div>
          </li>

          <li class="nav-item dropdown" dropdown *ngIf="currentUserIsAdmin$ | async">
            <a class="nav-link pointer" dropdownToggle role="button">
              <i class="far fa-chart-pie"></i> Reports <i class="far fa-caret-down"></i>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right" placement="bottom right">
              <a class="dropdown-item" *ngFor="let report of reports$ | async" [routerLink]="['/reports', report.id]">
                <i class="far fa-chart-pie"></i> {{ report.name }}</a
              >
            </div>
          </li>

          <li class="nav-item dropdown" dropdown>
            <a class="nav-link pointer" dropdownToggle role="button">
              <i class="far fa-user"></i> {{ (currentUser$ | async)?.firstName || 'User Menu' }}
              <i class="far fa-caret-down"></i>
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right" placement="bottom right">
              <a class="dropdown-item" (click)="signOut()"> <i class="far fa-sign-out"></i> Sign Out</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <app-cart-nav-counter [cartCount]="cartCount$ | async" class="pull-right"></app-cart-nav-counter>
  </nav>
</header>
