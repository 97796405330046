<form novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
      [disabled]="uploading"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>Upload Attachments</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <input type="file" #file style="display: none;" (change)="onFilesAdded()" multiple />
    <mat-list>
      <mat-list-item *ngFor="let file of files">
        <h4 mat-line>{{ file.name }}</h4>

        <mat-progress-bar *ngIf="progress" mode="determinate" [value]="progress[file.name].progress | async">
        </mat-progress-bar>
      </mat-list-item>
    </mat-list>
    <div class="upload-instructions text-center" *ngIf="files.size < 1">Add files to start the upload</div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <div class="float-right">
      <button *ngIf="files.size === 0" [disabled]="uploading" class="btn btn-primary btn-sm" (click)="addFiles()">
        Add Files
      </button>
      <button
        type="button"
        *ngIf="files.size > 0"
        class="btn btn-primary btn-sm"
        [disabled]="!canBeClosed"
        (click)="uploadFiles()"
      >
        Upload
      </button>
    </div>
  </div>
</form>
