import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Item, SubCustodyAssignment } from 'src/app/models';

@Component({
  selector: 'app-sub-custody-assignment-details',
  templateUrl: './sub-custody-assignment-details.component.html',
  styleUrls: ['./sub-custody-assignment-details.component.scss'],
})
export class SubCustodyAssignmentDetailsComponent implements OnInit {
  @Input() currentUserCanEdit: boolean[];
  @Input() items: Item[];
  @Input() subCustodyAssignment: SubCustodyAssignment;
  @Input() displayDeleteButton: boolean;
  @Output() removeItem = new EventEmitter();
  @Output() openCloneItemDialog = new EventEmitter();
  @Output() openItemNewDialog = new EventEmitter();
  @Output() openItemEditDialog = new EventEmitter();
  @Output() openSubCustodyAssignmentEditDialog = new EventEmitter();
  @Output() deleteSubCustodyAssignment = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
