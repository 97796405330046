import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of, combineLatest } from 'rxjs';
import { first, switchMap, tap, map } from 'rxjs/operators';
import { SubCustodyAssignmentDialogComponent } from '../components/sub-custody-assignments/sub-custody-assignment-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { SubCustodyAssignment } from '../models';
import { CustodiansService } from './custodians.service';
import { UsersService } from './users.service';

@Injectable()
export class SubCustodyAssignmentsService extends EntityCollectionServiceBase<SubCustodyAssignment> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    public dialog: MatDialog,
    private custodiansService: CustodiansService,
    private usersService: UsersService,
  ) {
    super('SubCustodyAssignment', serviceElementsFactory);
  }
  delete(entity: string | number | SubCustodyAssignment, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  openNewDialog(): Observable<SubCustodyAssignment> {
    return combineLatest([this.entities$, this.usersService.currentUserCanEdit$]).pipe(
      first(),
      switchMap(([subCustodyAssignments, currentUserCanEdit]) => {
        const dialogRef = this.dialog.open(SubCustodyAssignmentDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: {
            subCustodyAssignment: { number: null },
            title: 'Create New Sub-Custody Assignment',
            custodians$: this.custodiansService.entities$,
            currentUserCanEdit,
          },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  openEditDialog(subCustodyAssignment: SubCustodyAssignment): Observable<SubCustodyAssignment> {
    return combineLatest([this.entities$, this.usersService.currentUserCanEdit$]).pipe(
      first(),
      switchMap(([subCustodyAssignments, currentUserCanEdit]) => {
        const dialogRef = this.dialog.open(SubCustodyAssignmentDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: {
            subCustodyAssignment,
            title: 'Edit Sub-Custody Assignment',
            custodians$: this.custodiansService.entities$,
            currentUserCanEdit,
          },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  getAll() {
    const query = super.getWithQuery({
      $expand: `custodian`,
    });
    query.pipe(tap(() => this.setLoaded(true))).subscribe();
    return query;
  }
  getById(id: number) {
    const query = super.getWithQuery({
      $expand: 'custodian',
      $filter: `id eq ${id}`,
    });
    query.subscribe();
    return query.pipe(map(([item]) => item));
  }
}
