<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>{{ data.title }}</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <div class="form-group">
      <label for="name">Name</label>
      <input cdkFocusInitial formControlName="name" type="text" class="form-control" />
      <p *ngIf="form.get('name').invalid && form.get('name').dirty" class="text-danger">
        A Name for this room is required
      </p>
    </div>
    <div class="form-group">
      <label for="buildingId">Building</label>
      <select formControlName="buildingId" class="form-control">
        <option *ngFor="let building of data.buildings" [ngValue]="building.id">{{ building.name }}</option>
      </select>
      <p *ngIf="form.get('buildingId').invalid && form.get('buildingId').dirty" class="text-danger">
        A Building is required
      </p>
    </div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary float-right btn-sm">
      <fa-icon [icon]="faSave"></fa-icon> Save
    </button>
  </div>
</form>
