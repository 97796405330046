import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faInfoCircle, faPaperclip, faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UpdateItemLocations } from 'src/app/interfaces';
import { Building, Custodian, ItemExtended, Room, Site } from 'src/app/models';
import { BuildingsService } from 'src/app/services/buildings.service';
import { RoomsService } from 'src/app/services/rooms.service';

@Component({
  selector: 'app-update-item-location-dialog',
  templateUrl: './update-item-location-dialog.component.html',
  styles: [``],
})
export class UpdateItemLocationDialogComponent implements OnInit {
  public buildings$: Observable<Building[]>;
  public faInfoCircle = faInfoCircle;
  public faPaperclip = faPaperclip;
  public faSave = faSave;
  public faTimes = faTimes;
  public form: FormGroup;
  public rooms$: Observable<Room[]>;
  constructor(
    public dialogRef: MatDialogRef<UpdateItemLocationDialogComponent>,
    private fb: FormBuilder,
    private buildingsService: BuildingsService,
    private roomsService: RoomsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      custodians: Custodian[];
      items: ItemExtended[];
      sites: Site[];
    },
  ) {}

  ngOnInit() {
    const formFields: { [key: string]: any } = {
      assignedToId: [this.data.items[0].assignedToId],
      buildingId: [this.data.items[0].buildingId],
      location: [this.data.items[0].location],
      roomId: [this.data.items[0].roomId],
      siteId: [this.data.items[0].siteId, Validators.required],
    };
    this.form = this.fb.group(formFields);

    const siteId$ = this.form.get('siteId').valueChanges.pipe(startWith(this.data.items[0].siteId));

    this.buildings$ = combineLatest([siteId$, this.buildingsService.entities$]).pipe(
      map(([siteId, buildings]) => {
        if (siteId) {
          return buildings.filter((building) => building.siteId === siteId);
        } else {
          return [];
        }
      }),
    );

    const buildingId$ = this.form.get('buildingId').valueChanges.pipe(startWith(this.data.items[0].buildingId));

    this.rooms$ = combineLatest([buildingId$, this.roomsService.entities$]).pipe(
      map(([buildingId, rooms]) => {
        if (buildingId) {
          return rooms.filter((room) => room.buildingId === buildingId);
        } else {
          return [];
        }
      }),
    );
  }
  cancel() {
    this.dialogRef.close(null);
  }

  openNewBuildingModal() {
    this.buildingsService.openNewDialog(this.form.get('siteId').value || null).subscribe((building) => {
      if (building) {
        this.form.get('buildingId').setValue(building.id);
      }
    });
  }

  openNewRoomsModal() {
    this.roomsService.openNewDialog(this.form.get('buildingId').value || null).subscribe((room) => {
      if (room && room.id) {
        this.form.get('roomId').setValue(room.id);
      }
    });
  }

  save() {
    const formData = this.form.value;
    const updateItemLocationsPayload: UpdateItemLocations = {
      assignedToId: formData.assignedToId,
      buildingId: formData.buildingId,
      itemIds: this.data.items.map((item) => item.id),
      location: formData.location,
      roomId: formData.roomId,
      siteId: formData.siteId,
    };
    this.dialogRef.close(updateItemLocationsPayload);
  }
}
