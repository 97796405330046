import { Project } from './project';
import { ShippingLocation } from './shipping-location';
import { Item } from './item';

export enum ShippingOrderStatus {
  Pending = 'Pending',
  Shipped = 'Shipped',
}

export interface ShippingOrder {
  id: number;
  items?: Item[];
  itemsCount: number;
  notes: string;
  number: string;
  project?: Project;
  projectId: number;
  shippingDate: string;
  shippingLocation?: ShippingLocation;
  shippingLocationId: number;
  siteId: number;
  status: ShippingOrderStatus;
  trackingNumber: string;

  // Default metadata for all models
  createdBy?: number;
  createdTime?: string;
  updatedBy?: number;
  updatedTime?: string;
}
