import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, Site } from 'src/app/models';
import { SitesService } from 'src/app/services/sites.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styles: [``],
})
export class UsersComponent implements OnInit {
  public filterValue$: Observable<string>;
  public gridData$: Observable<GridDataResult>;
  public loaded$: Observable<boolean>;
  public pageSize = 40;
  public rowHeight = 30;
  public skip$ = new BehaviorSubject<number>(0);
  public sort$ = new BehaviorSubject<SortDescriptor[]>([
    {
      field: 'lastName',
      dir: 'asc',
    },
  ]);
  constructor(public dialog: MatDialog, private usersService: UsersService, private sitesService: SitesService) {}

  ngOnInit() {
    this.loaded$ = this.usersService.loaded$;
    this.filterValue$ = this.usersService.filter$;

    this.gridData$ = combineLatest([
      this.usersService.filteredEntities$,
      this.sitesService.entityMap$,
      this.sort$,
      this.skip$,
    ]).pipe(
      map(([users, sites, [sort], skip]) => {
        const data = sort.dir === 'asc' ? _.sortBy(users, sort.field) : _.sortBy(users, sort.field).reverse();
        const extendedData = data.map((user: User) => {
          const userSites = user.siteIds
            .map((id) => sites[id]?.name)
            .sort()
            .join(', ');
          return { ...user, userSites };
        });

        const pagedData = extendedData.slice(skip, skip + this.pageSize);
        return { data: pagedData, total: data.length };
      }),
    );
  }

  openEditDialog(user: User) {
    this.usersService.openEditDialog(user).subscribe();
  }

  filterUsers(filter) {
    this.usersService.setFilter(filter);
  }
  public sortChange(sort: SortDescriptor[]) {
    this.sort$.next(sort);
  }

  public pageChange(event: PageChangeEvent) {
    this.skip$.next(event.skip);
  }
}
