import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ProjectDialogComponent } from '../components/projects/project-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { Project } from '../models';

// interface ExtendedProject extends Project {
//   purchaseOrderCount: number;
//   itemCount: number;
// }

@Injectable({ providedIn: 'root' })
export class ProjectsService extends EntityCollectionServiceBase<Project> {
  // extendedEntities$: Observable<ExtendedProject[]>;
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    public dialog: MatDialog, // itemsService: ItemsService, // purchaseOrdersService: PurchaseOrdersService,
  ) {
    super('Project', serviceElementsFactory);
  }
  delete(entity: string | number | Project, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  openNewDialog(): Observable<Project> {
    return this.entities$.pipe(
      first(),
      switchMap((projects: Project[]) => {
        const dialogRef = this.dialog.open(ProjectDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { project: { name: null }, title: 'Create New Project', projects },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  openEditDialog(project: Project): Observable<Project> {
    return this.entities$.pipe(
      first(),
      switchMap((projects: Project[]) => {
        const dialogRef = this.dialog.open(ProjectDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { project, title: 'Edit Project', projects },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
}
