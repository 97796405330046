import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Rip } from 'src/app/models';
import { RipsService } from 'src/app/services/rips.service';

@Component({
  selector: 'app-rips',
  templateUrl: './rips.component.html',
  styles: [],
})
export class RipsComponent implements OnInit {
  public filterValue$: Observable<string>;
  public gridData$: Observable<GridDataResult>;
  public loaded$: Observable<boolean>;
  public pageSize = 40;
  public rowHeight = 30;
  public skip$ = new BehaviorSubject<number>(0);
  public sort$ = new BehaviorSubject<SortDescriptor[]>([
    {
      field: 'partNumber',
      dir: 'asc',
    },
  ]);
  constructor(public dialog: MatDialog, private ripsService: RipsService) {}

  ngOnInit() {
    this.loaded$ = this.ripsService.loaded$;
    this.filterValue$ = this.ripsService.filter$;

    this.gridData$ = combineLatest(this.ripsService.filteredEntities$, this.sort$, this.skip$).pipe(
      map(([rips, [sort], skip]) => {
        const data = sort.dir === 'asc' ? _.sortBy(rips, sort.field) : _.sortBy(rips, sort.field).reverse();

        const pagedData = data.slice(skip, skip + this.pageSize);
        return { data: pagedData, total: data.length };
      }),
    );
  }

  openNewDialog() {
    this.ripsService.openNewDialog().subscribe();
  }
  // openEditDialog(rip: Rip) {
  //   this.ripsService.openEditDialog(rip).subscribe();
  // }

  delete(rip: Rip) {
    this.ripsService.delete(rip);
  }
  filterRips(filter) {
    this.ripsService.setFilter(filter);
  }
  public sortChange(sort: SortDescriptor[]) {
    this.sort$.next(sort);
  }

  public pageChange(event: PageChangeEvent) {
    this.skip$.next(event.skip);
  }
}
