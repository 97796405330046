import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ApplicationRole } from '../enums';
import { UsersService } from '../services/users.service';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(private usersService: UsersService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.usersService.currentUserValue;
    if (currentUser && currentUser.role === ApplicationRole.AppAdmin) {
      // Authorized so return true
      return true;
    }
    return false;
  }
}
