import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Room } from 'src/app/models';
import { ItemsService } from 'src/app/services/items.service';
import { PurchaseOrdersService } from 'src/app/services/purchase-orders.service';
import { RoomsService } from 'src/app/services/rooms.service';

interface ExtendedRoom extends Room {
  purchaseOrderCount: number;
  itemCount: number;
}

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styles: [``],
})
export class RoomsComponent implements OnInit {
  public filterValue$: Observable<string>;
  public gridData$: Observable<GridDataResult>;
  public loaded$: Observable<boolean>;
  public pageSize = 40;
  public rowHeight = 30;
  public skip$ = new BehaviorSubject<number>(0);
  public sort$ = new BehaviorSubject<SortDescriptor[]>([
    {
      field: 'name',
      dir: 'asc',
    },
  ]);
  constructor(
    public dialog: MatDialog,
    private roomsService: RoomsService,
    private itemsService: ItemsService,
    private purchaseOrdersService: PurchaseOrdersService,
  ) {}

  ngOnInit() {
    this.loaded$ = this.roomsService.loaded$;
    this.filterValue$ = this.roomsService.filter$;

    this.gridData$ = combineLatest(this.roomsService.filteredEntities$, this.sort$, this.skip$).pipe(
      map(([rooms, [sort], skip]) => {
        const data = sort.dir === 'asc' ? _.sortBy(rooms, sort.field) : _.sortBy(rooms, sort.field).reverse();

        const pagedData = data.slice(skip, skip + this.pageSize);
        return { data: pagedData, total: data.length };
      }),
    );
  }

  openNewDialog() {
    this.roomsService.openNewDialog().subscribe();
  }
  openEditDialog(room: Room) {
    this.roomsService.openEditDialog(room).subscribe();
  }

  delete(room: Room) {
    this.roomsService.delete(room);
  }
  filterRooms(filter) {
    this.roomsService.setFilter(filter);
  }
  public sortChange(sort: SortDescriptor[]) {
    this.sort$.next(sort);
  }

  public pageChange(event: PageChangeEvent) {
    this.skip$.next(event.skip);
  }
}
