import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { PurchaseOrder } from 'src/app/models';
import { PurchaseOrdersService } from 'src/app/services/purchase-orders.service';
import * as _ from 'lodash';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-purchase-order-upload-dialog',
  templateUrl: './purchase-order-upload-dialog.component.html',
  styleUrls: ['./purchase-order-upload-dialog.component.scss'],
})
export class PurchaseOrderUploadDialogComponent implements OnInit {
  @ViewChild('file', { static: false }) file;
  public canBeClosed = true;
  public files: Set<File> = new Set();
  public progress: { [key: string]: { progress: Observable<number> } };
  public showCancelButton = true;
  public uploading = false;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<PurchaseOrderUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      purchaseOrder: PurchaseOrder;
      purchaseOrderService: PurchaseOrdersService;
    },
  ) {}

  ngOnInit() {}

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (const key in files) {
      if (!isNaN(Number(key))) {
        this.files.add(files[key]);
      }
    }
  }

  addFiles() {
    this.file.nativeElement.click();
  }
  cancel() {
    this.dialogRef.close(false);
  }

  uploadFiles() {
    // set the component state to "uploading"
    this.uploading = true;

    // start the upload and save the progress map
    this.progress = this.data.purchaseOrderService.uploadAttachments(this.data.purchaseOrder.id, this.files);

    const allProgressObservables = _.map(this.progress, (uploadStatus) => uploadStatus.progress);

    // The dialog should not be closed while uploading
    this.canBeClosed = false;
    this.dialogRef.disableClose = true;

    // Hide the cancel-button
    this.showCancelButton = false;

    // When all progress-observables are completed...
    forkJoin(allProgressObservables).subscribe((end) => {
      // ... the dialog can be closed again...
      this.canBeClosed = true;
      this.dialogRef.disableClose = false;

      // ... and the component is no longer uploading
      this.uploading = false;

      this.dialogRef.close(true);
    });
  }
}
