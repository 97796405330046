import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Custodian } from 'src/app/models';
import { CustodiansService } from 'src/app/services/custodians.service';

@Component({
  selector: 'app-custodians',
  templateUrl: './custodians.component.html',
  styles: [``],
})
export class CustodiansComponent implements OnInit {
  public filterValue$: Observable<string>;
  public gridData$: Observable<GridDataResult>;
  public loaded$: Observable<boolean>;
  public pageSize = 40;
  public rowHeight = 30;
  public skip$ = new BehaviorSubject<number>(0);
  public sort$ = new BehaviorSubject<SortDescriptor[]>([
    {
      field: 'name',
      dir: 'asc',
    },
  ]);
  constructor(public dialog: MatDialog, private custodiansService: CustodiansService) {}

  ngOnInit() {
    this.loaded$ = this.custodiansService.loaded$;
    this.filterValue$ = this.custodiansService.filter$;

    this.gridData$ = combineLatest(this.custodiansService.filteredEntities$, this.sort$, this.skip$).pipe(
      map(([custodians, [sort], skip]) => {
        const data = sort.dir === 'asc' ? _.sortBy(custodians, sort.field) : _.sortBy(custodians, sort.field).reverse();

        const pagedData = data.slice(skip, skip + this.pageSize);
        return { data: pagedData, total: data.length };
      }),
    );
  }

  openNewDialog() {
    this.custodiansService.openNewDialog().subscribe();
  }
  openEditDialog(custodian: Custodian) {
    this.custodiansService.openEditDialog(custodian).subscribe();
  }

  delete(custodian: Custodian) {
    this.custodiansService.delete(custodian);
  }
  filterCustodians(filter) {
    this.custodiansService.setFilter(filter);
  }
  public sortChange(sort: SortDescriptor[]) {
    this.sort$.next(sort);
  }

  public pageChange(event: PageChangeEvent) {
    this.skip$.next(event.skip);
  }
}
