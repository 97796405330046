import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { ShippingLocationDialogComponent } from '../components/shipping-locations/shipping-location-dialog.component';
import { ShippingLocation } from '../models';
import { ProjectsService } from './projects.service';
import { RipsService } from './rips.service';
import { VendorsService } from './vendors.service';

@Injectable({ providedIn: 'root' })
export class ShippingLocationsService extends EntityCollectionServiceBase<ShippingLocation> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, public dialog: MatDialog) {
    super('ShippingLocation', serviceElementsFactory);
  }
  getAll(options?: EntityActionOptions) {
    const query = this.getWithQuery({}, options);
    query.pipe(tap(() => this.setLoaded(true))).subscribe();
    return query;
  }
  getById(id: number, options?: EntityActionOptions) {
    const query = this.getWithQuery(
      {
        $filter: `id eq ${id}`,
      },
      options,
    );
    query.pipe(tap(() => this.setLoaded(true))).subscribe();
    return query;
  }
  add(entity: ShippingLocation, options?: EntityActionOptions) {
    const query = super.add(entity, options);
    query
      .pipe(
        switchMap((e) =>
          this.getWithQuery({
            $filter: `id eq ${e.id}`,
          }),
        ),
      )
      .subscribe();
    return query;
  }
  delete(entity: string | number | ShippingLocation, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  update(entity: ShippingLocation, options?: EntityActionOptions) {
    const query = super.update(entity, options);
    query
      .pipe(
        switchMap((e) =>
          this.getWithQuery({
            $filter: `id eq ${e.id}`,
          }),
        ),
      )
      .subscribe();
    return query;
  }
  openNewDialog(): Observable<ShippingLocation> {
    const dialogRef = this.dialog.open(ShippingLocationDialogComponent, {
      disableClose: true,
      minWidth: '70%',
      data: {
        title: 'Create Shipping Location',
        shippingLocation: {
          address1: null,
          address2: null,
          city: null,
          id: undefined,
          name: null,
          state: null,
          zip: null,
        },
      },
    });

    return dialogRef.afterClosed().pipe(
      first(),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }

  openEditDialog(shippingLocation: ShippingLocation): Observable<ShippingLocation> {
    const dialogRef = this.dialog.open(ShippingLocationDialogComponent, {
      minWidth: '70%',
      disableClose: true,
      data: { shippingLocation, title: 'Edit Shipping Location' },
    });

    return dialogRef.afterClosed().pipe(
      first(),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
}
