import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { ItemExtended, PurchaseOrderExtended, PurchaseOrderAttachment } from 'src/app/models';
import { ItemsService } from 'src/app/services/items.service';
import { PurchaseOrdersService } from 'src/app/services/purchase-orders.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-purchase-order-container-details',
  template: `
    <app-purchase-order-details
      *ngIf="loaded$ | async; else loading"
      [items$]="items$"
      [attachments]="attachments$ | async"
      [purchaseOrder]="purchaseOrder$ | async"
      [purchaseOrderTotal]="purchaseOrderTotal$ | async"
      [currentUserCanEdit]="currentUserCanEdit$ | async"
      (deleteAttachment)="deleteAttachment($event)"
      (deleteItem)="deleteItem($event)"
      (openCloneItemDialog)="openCloneItemDialog($event)"
      (openItemNewDialog)="openItemNewDialog($event)"
      (openItemEditDialog)="openItemEditDialog($event)"
      (openPurchaseOrderEditDialog)="openPurchaseOrderEditDialog($event)"
      (openUploadAttachmentDialog)="openUploadAttachmentDialog($event)"
    >
    </app-purchase-order-details>

    <ng-template #loading>
      <div class="loading-spinner"></div>
    </ng-template>
  `,
  styles: [],
})
export class PurchaseOrderDetailsContainerComponent implements OnInit {
  public attachments$ = new BehaviorSubject<PurchaseOrderAttachment[]>([]);
  public currentUserCanEdit$: Observable<boolean>;
  public items$: Observable<ItemExtended[]>;
  public loaded$: Observable<boolean>;
  public purchaseOrder$: Observable<PurchaseOrderExtended>;
  public purchaseOrderId: number;
  public purchaseOrderTotal$: Observable<number>;
  constructor(
    public dialog: MatDialog,
    public purchaseOrdersService: PurchaseOrdersService,
    private itemsService: ItemsService,
    private route: ActivatedRoute,
    private userService: UsersService,
  ) {}

  ngOnInit() {
    this.purchaseOrderId = Number(this.route.snapshot.params.id);
    this.itemsService.getByPurchaseOrderId(this.purchaseOrderId).subscribe();
    this.purchaseOrdersService.getById(this.purchaseOrderId).subscribe();
    this.loaded$ = this.purchaseOrdersService.loaded$;
    this.currentUserCanEdit$ = this.userService.currentUserCanEdit$;

    this.items$ = this.itemsService.entities$.pipe(
      map((items) => items.filter((item) => item.purchaseOrderId === this.purchaseOrderId)),
      map((items) => _.sortBy(items, 'id')),
    );
    this.purchaseOrder$ = this.purchaseOrdersService.entityMap$.pipe(
      map((purchaseOrderDictionary) => purchaseOrderDictionary[this.purchaseOrderId]),
      filter((purchaseOrder) => !!purchaseOrder),
    );
    this.purchaseOrderTotal$ = this.items$.pipe(
      map((items) => {
        let total = 0;
        items.forEach((item) => {
          if (!isNaN(item.totalCost)) {
            total += item.totalCost;
          }
        });
        return total;
      }),
    );
    this.updateAttachments();
  }
  deleteAttachment(attachmentId: number) {
    this.purchaseOrdersService.deleteAttachment(attachmentId).subscribe((deleted) => {
      if (deleted) {
        this.updateAttachments();
      }
    });
  }
  deleteItem(item: ItemExtended) {
    this.itemsService.delete(item.id);
  }
  openCloneItemDialog(item: ItemExtended) {
    this.itemsService.openNewDialog(item).subscribe();
  }
  openItemNewDialog() {
    this.purchaseOrder$.pipe(first()).subscribe((po) => {
      this.itemsService
        .openNewDialog({ purchaseOrderId: this.purchaseOrderId, siteId: po.siteId, projectId: po.projectId })
        .subscribe();
    });
  }
  openItemEditDialog(item: ItemExtended) {
    this.itemsService.openEditDialog(item.id).subscribe();
  }
  openPurchaseOrderEditDialog(purchaseOrder: PurchaseOrderExtended) {
    this.purchaseOrdersService.openEditDialog(purchaseOrder).subscribe();
  }
  openUploadAttachmentDialog(purchaseOrder: PurchaseOrderExtended) {
    this.purchaseOrdersService.openUploadAttachmentDialog(purchaseOrder).subscribe((uploaded) => {
      if (uploaded) {
        this.updateAttachments();
      }
    });
  }
  updateAttachments() {
    this.purchaseOrdersService
      .getAttachmentsByPurchaseOrderId(this.purchaseOrderId)
      .subscribe((attachments) => this.attachments$.next(attachments));
  }
}
