import { Component, OnChanges, Input, ChangeDetectionStrategy } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-name',
  template: `{{ userName$ | async }}`,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNameComponent implements OnChanges {
  @Input() userId: number;
  public userName$: Observable<string>;
  constructor(private usersService: UsersService) {}

  ngOnChanges() {
    this.userName$ = this.usersService.entityMap$.pipe(
      map((users) => (this.userId ? users[this.userId] : null)),
      map((user) => (!!user ? `${user.firstName} ${user.lastName}` : '')),
    );
  }
}
