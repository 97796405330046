import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from '../models';
import { BuildingsService } from '../services/buildings.service';
import { CustodiansService } from '../services/custodians.service';
import { ItemsService } from '../services/items.service';
import { ManufacturersService } from '../services/manufacturers.service';
import { PartsService } from '../services/parts.service';
import { ProjectsService } from '../services/projects.service';
import { PurchaseOrdersService } from '../services/purchase-orders.service';
import { ReportsService } from '../services/reports.service';
import { RipsService } from '../services/rips.service';
import { RoomsService } from '../services/rooms.service';
import { ShippingLocationsService } from '../services/shipping-locations.service';
import { ShippingOrdersService } from '../services/shipping-orders.service';
import { SitesService } from '../services/sites.service';
import { SubCustodyAssignmentsService } from '../services/sub-custody-assignments.service';
import { UsersService } from '../services/users.service';
import { VendorsService } from '../services/vendors.service';

export interface ItemHistory {
  item: Item;
  periodStart: string;
  periodEnd: string;
}

export interface ItemChangeSummary {
  changes: ItemChange[];
  version: number;
  periodStart: string;
  periodEnd: string;
  changedBy: string;
}

interface ItemChange {
  field: string;
  oldValue: string;
  newValue: string;
}

@Injectable({ providedIn: 'root' })
export class ItemHistoryFactory {
  constructor(
    private buildingsService: BuildingsService,
    private custodiansService: CustodiansService,
    private itemsService: ItemsService,
    private manufacturersService: ManufacturersService,
    private partsService: PartsService,
    private projectsService: ProjectsService,
    private purchaseOrdersService: PurchaseOrdersService,
    private reportsService: ReportsService,
    private ripsService: RipsService,
    private roomsService: RoomsService,
    private shippingLocationsService: ShippingLocationsService,
    private shippingOrdersService: ShippingOrdersService,
    private sitesService: SitesService,
    private subCustodyAssignmentsService: SubCustodyAssignmentsService,
    private usersService: UsersService,
    private vendorsService: VendorsService,
  ) {}

  // Using the example above, we want to populate the ItemHistoryExtended[] by pulling the data from each of the services
  public getChanges(versions: ItemHistory[]) {
    return combineLatest([
      // this.buildingsService.entityMap$,
      // this.custodiansService.entityMap$,
      // this.itemsService.entityMap$,
      // this.manufacturersService.entityMap$,
      // this.partsService.entityMap$,
      // this.projectsService.entityMap$,
      // this.purchaseOrdersService.entityMap$,
      // this.ripsService.entityMap$,
      // this.roomsService.entityMap$,
      // this.shippingLocationsService.entityMap$,
      // this.shippingOrdersService.entityMap$,
      // this.sitesService.entityMap$,
      // this.subCustodyAssignmentsService.entityMap$,
      this.usersService.entityMap$,
      this.vendorsService.entityMap$,
    ]).pipe(
      map(
        ([
          // buildings,
          // custodians,
          // items,
          // manufacturers,
          // parts,
          // projects,
          // purchaseOrders,
          // rips,
          // rooms,
          // shippingLocations,
          // shippingOrders,
          // sites,
          // subCustodyAssignments,
          users,
          vendors,
        ]) => {
          const itemChangeSummaries: ItemChangeSummary[] = [];
          // Iterate over each item in the validHistoryItems array
          versions.forEach((item, index) => {
            // The last item is the current version so we don't need to continue once we get there
            if (index < versions.length - 1) {
              const changes = this.findChanges(item.item, versions[index + 1].item);
              const changedByUser = users[versions[index + 1].item.updatedBy];
              // Ignore changes if user hit save without making any changes
              if (changes.length > 0) {
                itemChangeSummaries.push({
                  changes,
                  periodStart: versions[index + 1].periodStart,
                  periodEnd: versions[index + 1].periodEnd,
                  version: index + 1,
                  changedBy: `${changedByUser.firstName} ${changedByUser.lastName}`,
                });
              }
            }
          });

          return itemChangeSummaries;
        },
      ),
    );
  }
  //
  private findChanges(item: Item, previousItem: Item) {
    const changes: ItemChange[] = [];
    const fieldsToIgnore = ['createdBy', 'createdTime', 'updatedBy', 'updatedTime'];
    // Iterate over each property in the item and compare it to the previousItem
    // If the value is different, add it to the changes array
    Object.entries(item).forEach(([key, value]) => {
      if (!fieldsToIgnore.includes(key) && value !== previousItem[key]) {
        changes.push({
          field: key,
          oldValue: previousItem[key],
          newValue: value,
        });
      }
    });
    return changes;
  }
}
