import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import buildQuery from 'odata-query';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { PurchaseOrderUploadDialogComponent } from '../components/purchase-order-upload-dialog/purchase-order-upload-dialog.component';
import { PurchaseOrderDialogComponent } from '../components/purchase-orders/purchase-order-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { PurchaseOrderAttachment, PurchaseOrderExtended } from '../models';
import { ProjectsService } from './projects.service';
import { RipsService } from './rips.service';
import { SitesService } from './sites.service';
@Injectable({ providedIn: 'root' })
export class PurchaseOrdersService extends EntityCollectionServiceBase<PurchaseOrderExtended> {
  public classificationOptions = ['CAP', 'GFP'];
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private projectsService: ProjectsService,
    private ripsService: RipsService,
    public dialog: MatDialog,
    private router: Router,
    private http: HttpClient,
    private sitesService: SitesService,
  ) {
    super('PurchaseOrder', serviceElementsFactory);
  }
  add(entity: PurchaseOrderExtended, options?: EntityActionOptions) {
    const query = super.add(entity, options);
    query
      .pipe(
        // Navigate to PO Details page so user can add
        tap((e) => this.router.navigate(['receiving-document', e.id])),
      )
      .subscribe();
    return query;
  }
  delete(entity: string | number | PurchaseOrderExtended, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  deleteAttachment(attachmentId: number) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    return dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return this.http.delete(`/api/PurchaseOrderAttachments/${attachmentId}`).pipe(map(() => true));
        } else {
          return of(false);
        }
      }),
    );
  }
  getAttachmentsByPurchaseOrderId(purchaseOrderId: number) {
    const query = buildQuery({ filter: { purchaseOrderId: purchaseOrderId } });
    return this.http.get<PurchaseOrderAttachment[]>(`/api/PurchaseOrderAttachments${query}`);
  }
  getById(id: number) {
    const query = super.getWithQuery({
      $filter: `id eq ${id}`,
    });
    query.subscribe();
    return query.pipe(map(([item]) => item));
  }
  getEntities(active = true) {
    const query = super.getWithQuery({});
    this.setLoading(true);
    query.subscribe((items) => {
      if (active) {
        this.setLoaded(true);
      }
      this.setLoading(false);
    });
    return query.pipe(map((items) => items));
  }
  update(entity: PurchaseOrderExtended, options?: EntityActionOptions) {
    return super.update(entity, options);
  }
  openNewDialog(): Observable<PurchaseOrderExtended> {
    return combineLatest([
      this.ripsService.entities$.pipe(filter((e) => e.length > 0)),
      this.projectsService.entities$.pipe(filter((e) => e.length > 0)),
      this.sitesService.userSites$.pipe(filter((e) => e.length > 0)),
    ]).pipe(
      first(),
      switchMap(([rips, projects, sites]) => {
        const dialogRef = this.dialog.open(PurchaseOrderDialogComponent, {
          disableClose: true,
          minWidth: '70%',
          data: {
            title: 'Create Receiving Document',
            rips,
            projects,
            sites,
            purchaseOrder: {
              deliveryDate: null,
              id: undefined,
              number: null,
              orderDate: null,
              projectId: null,
              ripNumber: null,
              shippingDate: null,
              siteId: sites[0]?.id,
              status: 'Pending',
              trackingNumber: null,
              vendorId: null,
              vendorOrderNumber: null,
            },
            classificationOptions: this.classificationOptions,
          },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  openEditDialog(purchaseOrder: PurchaseOrderExtended): Observable<PurchaseOrderExtended> {
    return combineLatest(
      this.ripsService.entities$.pipe(filter((e) => e.length > 0)),
      this.projectsService.entities$.pipe(filter((e) => e.length > 0)),
      this.sitesService.userSites$.pipe(filter((e) => e.length > 0)),
    ).pipe(
      first(),
      switchMap(([rips, projects, sites]) => {
        const dialogRef = this.dialog.open(PurchaseOrderDialogComponent, {
          minWidth: '70%',
          disableClose: true,
          data: {
            purchaseOrder,
            title: 'Edit Receiving Document',
            rips,
            projects,
            sites,
            classificationOptions: this.classificationOptions,
          },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  openUploadAttachmentDialog(purchaseOrder: PurchaseOrderExtended) {
    const dialogRef = this.dialog.open(PurchaseOrderUploadDialogComponent, {
      minWidth: '70%',
      disableClose: true,
      data: {
        purchaseOrder,
        purchaseOrderService: this,
      },
    });

    return dialogRef.afterClosed();
  }

  uploadAttachments(purchaseOrderId: number, files: Set<File>): { [key: string]: { progress: Observable<number> } } {
    // this will be the our resulting map
    const status: { [key: string]: { progress: Observable<number> } } = {};

    files.forEach((file) => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', `/api/PurchaseOrderAttachments/${purchaseOrderId}`, formData, {
        reportProgress: true,
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          // calculate the progress percentage

          const percentDone = Math.round((100 * event.loaded) / event.total);
          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
        }
      });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable(),
      };
    });

    // return the map of progress.observables
    return status;
  }
}
