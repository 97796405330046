import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { UserDialogComponent } from '../components/users/user-dialog.component';
import { User } from '../models';
import { ApplicationInsightsService } from './application-insights.service';
import { ApplicationRole } from '../enums';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable({ providedIn: 'root' })
export class UsersService extends EntityCollectionServiceBase<User> {
  private currentUserSubject$: BehaviorSubject<User>;
  public currentUser$: Observable<User>;
  public currentUserIsAdmin$: Observable<boolean>;
  public currentUserIsAppAdmin$: Observable<boolean>;
  public currentUserCanEdit$: Observable<boolean>;

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private applicationInsightsService: ApplicationInsightsService,
    private http: HttpClient,
    public dialog: MatDialog,
    private router: Router,
    private authService: MsalService,
  ) {
    super('User', serviceElementsFactory);
    this.currentUserSubject$ = new BehaviorSubject<User>(null);
    this.currentUser$ = combineLatest([this.currentUserSubject$, this.entityMap$]).pipe(
      map(([userRegistration, userDictionary]) => {
        if (userRegistration && userDictionary[userRegistration.id]) {
          return userDictionary[userRegistration.id];
        } else if (userRegistration) {
          return userRegistration;
        }
      }),
    );

    this.currentUserIsAdmin$ = this.currentUser$.pipe(
      map((currentUser) => {
        return (
          !!currentUser &&
          (currentUser.role === ApplicationRole.AppAdmin || currentUser.role === ApplicationRole.SiteAdmin)
        );
      }),
    );
    this.currentUserIsAppAdmin$ = this.currentUser$.pipe(
      map((currentUser) => {
        return !!currentUser && currentUser.role === ApplicationRole.AppAdmin;
      }),
    );
    this.currentUserCanEdit$ = this.currentUser$.pipe(
      map((currentUser) => {
        return (
          !!currentUser &&
          (currentUser.role === ApplicationRole.AppAdmin ||
            currentUser.role === ApplicationRole.SiteAdmin ||
            currentUser.role === ApplicationRole.Contributor)
        );
      }),
    );
  }
  public get currentUserValue(): User {
    return this.currentUserSubject$.value;
  }
  authenticate() {
    return this.http.get<User>(`/api/users/authenticate`).pipe(
      map((user) => {
        this.currentUserSubject$.next(user);
        return user;
      }),
      tap((user) => {
        if (!user.role) {
          this.router.navigate(['/403']);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.router.navigate(['/403']);
        }
        return of(null);
      }),
    );
  }

  logout() {
    this.currentUserSubject$.next(null);
    this.applicationInsightsService.clearUserId();
    this.authService.logout();
  }
  openEditDialog(user: User): Observable<User> {
    return this.entities$.pipe(
      first(),
      switchMap((users: User[]) => {
        const dialogRef = this.dialog.open(UserDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { user, title: 'Edit User', users },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
}
