import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemExtended, PurchaseOrderAttachment, PurchaseOrderExtended } from 'src/app/models';

@Component({
  selector: 'app-purchase-order-details',
  templateUrl: './purchase-order-details.component.html',
  styleUrls: ['./purchase-order-details.component.scss'],
})
export class PurchaseOrderDetailsComponent implements OnInit {
  @Input() attachments: PurchaseOrderAttachment[];
  @Input() currentUserCanEdit: boolean[];
  @Input() items$: Observable<ItemExtended[]>;
  @Input() purchaseOrder: PurchaseOrderExtended;
  @Input() purchaseOrderTotal: number;
  @Output() deleteAttachment = new EventEmitter();
  @Output() deleteItem = new EventEmitter();
  @Output() openCloneItemDialog = new EventEmitter();
  @Output() openItemNewDialog = new EventEmitter();
  @Output() openItemEditDialog = new EventEmitter();
  @Output() openPurchaseOrderEditDialog = new EventEmitter();
  @Output() openUploadAttachmentDialog = new EventEmitter();
  public filterValue$: Observable<string>;
  public gridData$: Observable<GridDataResult>;
  public loaded$: Observable<boolean>;
  public pageSize = 50;
  public rowHeight = 30;
  public skip$ = new BehaviorSubject<number>(0);
  public sort$ = new BehaviorSubject<SortDescriptor[]>([
    {
      field: 'createdTime',
      dir: 'desc',
    },
  ]);
  constructor() {}

  ngOnInit() {
    this.gridData$ = combineLatest([this.items$, this.sort$, this.skip$]).pipe(
      map(([items, [sort], skip]) => {
        const data = sort.dir === 'asc' ? _.sortBy(items, sort.field) : _.sortBy(items, sort.field).reverse();

        const pagedData = data.slice(skip, skip + this.pageSize);
        return { data: pagedData, total: data.length };
      }),
    );
  }
  public sortChange(sort: SortDescriptor[]) {
    this.sort$.next(sort);
  }

  public pageChange(event: PageChangeEvent) {
    this.skip$.next(event.skip);
  }
}
