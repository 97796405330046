import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, tap, switchMap } from 'rxjs/operators';
import { Custodian, Item, ItemExtended, Site } from 'src/app/models';
import * as cartActions from '../../actions/cart.actions';
import * as fromRoot from '../../reducers';
import { AddCartToShippingOrderDialogComponent } from './add-cart-to-shipping-order-dialog/add-cart-to-shipping-order-dialog.component';
import { ItemsService } from 'src/app/services/items.service';
import { ShippingOrdersService } from 'src/app/services/shipping-orders.service';
import { ItemStatus } from 'src/app/enums';
import { SubCustodyAssignmentsService } from 'src/app/services/sub-custody-assignments.service';
import { BuildingsService } from 'src/app/services/buildings.service';
import { RoomsService } from 'src/app/services/rooms.service';
import { SitesService } from 'src/app/services/sites.service';
import { UpdateItemLocationDialogComponent } from '../items/update-item-location-dialog.component';
import { UpdateItemLocations } from 'src/app/interfaces';
import { CustodiansService } from 'src/app/services/custodians.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UpdateProjectForCartItemsDialogComponent } from './update-project-for-cart-items-dialog/update-project-for-cart-items-dialog.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  public itemsInCart$: Observable<ItemExtended[]>;
  public loaded$: Observable<boolean>;
  public loading$ = new BehaviorSubject<boolean>(false);
  constructor(
    private itemsService: ItemsService,
    private router: Router,
    private custodiansService: CustodiansService,
    private shippingOrdersService: ShippingOrdersService,
    private subCustodyAssignmentsService: SubCustodyAssignmentsService,
    private store: Store<fromRoot.State>,
    private buildingsService: BuildingsService,
    private roomsService: RoomsService,
    private sitesService: SitesService,
    private projectsService: ProjectsService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.loaded$ = this.itemsService.loaded$;
    const itemMap$ = this.itemsService.entityMap$.pipe(filter((itemMap) => !_.isEmpty(itemMap)));
    this.itemsInCart$ = combineLatest([this.store.select(fromRoot.selectCartIds), itemMap$]).pipe(
      map(([cartIds, itemMap]) => {
        // Only display valid items
        return cartIds.filter((id) => itemMap[id]).map((id) => itemMap[id]);
      }),
    );
  }

  public clearCart() {
    this.store.dispatch(cartActions.reset());
  }

  public openAddItemsToShippingOrderDialog() {
    this.shippingOrdersService.entities$
      .pipe(
        first(),
        tap((shippingOrders) => {
          const dialogRef = this.dialog.open(AddCartToShippingOrderDialogComponent, {
            disableClose: true,
            minWidth: '40%',
            data: {
              shippingOrders,
            },
          });
          dialogRef.afterClosed().subscribe((shippingOrderId: number) => {
            if (shippingOrderId) {
              this.addItemsToShippingOrder(shippingOrderId);
            }
          });
        }),
      )
      .subscribe();
  }

  public openItemEditDialog(itemId: number) {
    this.itemsService.openEditDialog(itemId).subscribe();
  }

  public openNewShippingOrderModal() {
    this.shippingOrdersService.openNewDialog().subscribe((newShippingOrder) => {
      if (newShippingOrder) {
        this.addItemsToShippingOrder(newShippingOrder.id);
      }
    });
  }

  public openNewSubCustodyAssignmentModal() {
    this.subCustodyAssignmentsService.openNewDialog().subscribe((newSubCustodyAssignment) => {
      if (newSubCustodyAssignment) {
        this.addItemsToSubCustodyAssignment(newSubCustodyAssignment.id, newSubCustodyAssignment.custodianId);
      }
    });
  }

  public openUpdateProjectDialog() {
    this.projectsService.entities$
      .pipe(
        first(),
        tap((projects) => {
          const dialogRef = this.dialog.open(UpdateProjectForCartItemsDialogComponent, {
            minWidth: '40%',
            disableClose: true,
            data: {
              projects,
            },
          });
          dialogRef.afterClosed().subscribe((projectId: number) => {
            if (projectId) {
              this.updateProjectForItems(projectId);
            }
          });
        }),
      )
      .subscribe();
  }

  public openUpdateLocationDialog() {
    this.loading$.next(true);
    const modalDataReady$ = combineLatest([
      this.buildingsService.loaded$,
      this.roomsService.loaded$,
      this.sitesService.loaded$,
      this.custodiansService.loaded$,
    ]).pipe(
      map(
        ([buildingsLoaded, roomsLoaded, sitesLoaded, custodiansLoaded]) =>
          buildingsLoaded && roomsLoaded && sitesLoaded && custodiansLoaded,
      ),
    );

    combineLatest([modalDataReady$, this.sitesService.userSites$, this.custodiansService.entities$, this.itemsInCart$])
      .pipe(
        filter(([modalDataReady, sites, custodians]: [boolean, Site[], Custodian[], ItemExtended[]]) => modalDataReady),
        first(),
        switchMap(([modalDataReady, sites, custodians, items]) => {
          const dialogRef = this.dialog.open(UpdateItemLocationDialogComponent, {
            minWidth: '60%',
            disableClose: true,
            data: {
              items,
              sites,
              custodians,
            },
          });

          return dialogRef.afterClosed();
        }),
        switchMap((result: UpdateItemLocations) => {
          if (result) {
            return this.itemsService.updateLocations(result);
          } else {
            return of(false);
          }
        }),
      )
      .subscribe((savedChanges: boolean) => {
        if (savedChanges) {
          this.clearCart();
          this.router.navigate(['/']);
        }
        this.loading$.next(false);
      });
  }

  public removeFromCart(id: number) {
    this.store.dispatch(cartActions.removeItem({ id }));
  }

  private addItemsToShippingOrder(shippingOrderId: number) {
    this.itemsInCart$
      .pipe(
        first(),
        switchMap((items) => {
          const updates = items.map((item) => {
            return this.itemsService
              .update({
                ...item,
                assignedToId: null,
                shippingOrderId: shippingOrderId,
                status: ItemStatus.Shipped,
                subCustodyAssignmentId: null,
              })
              .pipe(tap((updatedItem) => this.removeFromCart(updatedItem.id)));
          });

          return combineLatest(updates);
        }),
        tap(() => this.router.navigate(['./shipping-order', shippingOrderId])),
      )
      .subscribe();
  }

  private updateProjectForItems(projectId: number) {
    this.itemsInCart$
      .pipe(
        first(),
        switchMap((items) => {
          const updates = items.map((item) => {
            return this.itemsService
              .update({ ...item, projectId })
              .pipe(tap((updatedItem) => this.removeFromCart(updatedItem.id)));
          });

          return combineLatest(updates);
        }),
      )
      .subscribe();
  }

  private addItemsToSubCustodyAssignment(subCustodyAssignmentId: number, assignedToId: number) {
    this.itemsInCart$
      .pipe(
        first(),
        switchMap((items) => {
          const updates = items.map((item) => {
            return this.itemsService
              .update({
                ...item,
                assignedToId: assignedToId,
                status: ItemStatus.CheckedOut,
                subCustodyAssignmentId,
              })
              .pipe(tap((updatedItem) => this.removeFromCart(updatedItem.id)));
          });

          return combineLatest(updates);
        }),
        tap(() => this.router.navigate(['./sub-custody-assignment', subCustodyAssignmentId])),
      )
      .subscribe();
  }
}
