import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from 'src/app/models';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-project-dialog',
  templateUrl: './project-dialog.component.html',
  styles: [``],
})
export class ProjectDialogComponent implements OnInit {
  public form: FormGroup;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<ProjectDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { project: Project; title: string; projects: Project[] },
  ) {}

  ngOnInit() {
    const formFields: { [key: string]: any } = {
      code: [this.data.project.code, Validators.required],
      contractNumber: [this.data.project.contractNumber, Validators.required],
      tdlNumber: [this.data.project.tdlNumber, Validators.required],
    };
    if (this.data.project.id) {
      formFields.id = [this.data.project.id];
      formFields.createdBy = [this.data.project.createdBy];
      formFields.createdTime = [this.data.project.createdTime];
    }
    this.form = this.fb.group(formFields);
  }
  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.form.value);
  }
}
