import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShippingLocation } from 'src/app/models';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-shipping-location-edit-dialog',
  templateUrl: './shipping-location-dialog.component.html',
  styles: [``],
})
export class ShippingLocationDialogComponent implements OnInit {
  public form: FormGroup;
  public title = 'Edit Shipping Location';
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<ShippingLocationDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shippingLocation: ShippingLocation;
      title: string;
    },
  ) {}

  ngOnInit() {
    const formFields: { [key: string]: any } = {
      address1: [this.data.shippingLocation.address1, Validators.required],
      address2: [this.data.shippingLocation.address2],
      city: [this.data.shippingLocation.city],
      name: [this.data.shippingLocation.name, Validators.required],
      state: [this.data.shippingLocation.state],
      zip: [this.data.shippingLocation.zip],
    };
    if (this.data.shippingLocation.id) {
      formFields.id = [this.data.shippingLocation.id];
      formFields.createdBy = [this.data.shippingLocation.createdBy];
      formFields.createdTime = [this.data.shippingLocation.createdTime];
    }
    this.form = this.fb.group(formFields);
  }
  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.form.value);
  }
}
