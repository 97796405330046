import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-grid-filter-input',
  template: `
    <input
      type="search"
      #filterInput
      (keydown.escape)="filterInput.blur()"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      (click)="$event.target.select()"
    />
  `,
  styleUrls: ['./grid-filter-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GridFilterInputComponent), multi: true }],
})
export class GridFilterInputComponent implements ControlValueAccessor {
  // tslint:disable-next-line: no-input-rename
  @Input('value') _value: string;
  @Input() placeholder = 'Filter';

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
}
