import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ItemExtended, ShippingOrder, ShippingOrderAttachment, ShippingOrderExtended } from 'src/app/models';
import { ItemsService } from 'src/app/services/items.service';
import { ShippingOrdersService } from 'src/app/services/shipping-orders.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-shipping-order-container-details',
  template: `
    <app-shipping-order-details
      *ngIf="loaded$ | async; else loading"
      [items$]="items$"
      [attachments]="attachments$ | async"
      [currentUserCanEdit]="currentUserCanEdit$ | async"
      [shippingOrder]="shippingOrder$ | async"
      (removeItem)="removeItem($event)"
      (deleteAttachment)="deleteAttachment($event)"
      (openCloneItemDialog)="openCloneItemDialog($event)"
      (openItemNewDialog)="openItemNewDialog($event)"
      (openItemEditDialog)="openItemEditDialog($event)"
      (openShippingOrderEditDialog)="openShippingOrderEditDialog($event)"
      (openUploadAttachmentDialog)="openUploadAttachmentDialog($event)"
    >
    </app-shipping-order-details>

    <ng-template #loading>
      <div class="loading-spinner"></div>
    </ng-template>
  `,
  styles: [],
})
export class ShippingOrderDetailsContainerComponent implements OnInit {
  public currentUserCanEdit$: Observable<boolean>;
  public loaded$: Observable<boolean>;
  public shippingOrder$: Observable<ShippingOrderExtended>;
  public items$: Observable<ItemExtended[]>;
  public attachments$ = new BehaviorSubject<ShippingOrderAttachment[]>([]);
  public shippingOrderId: number;
  constructor(
    public dialog: MatDialog,
    public shippingOrdersService: ShippingOrdersService,
    private itemsService: ItemsService,
    private route: ActivatedRoute,
    private userService: UsersService,
  ) {}

  ngOnInit() {
    this.shippingOrderId = Number(this.route.snapshot.params.id);
    this.itemsService.getByShippingOrderId(this.shippingOrderId).subscribe();
    this.shippingOrdersService.getById(this.shippingOrderId).subscribe();
    this.loaded$ = this.shippingOrdersService.loaded$;
    this.currentUserCanEdit$ = this.userService.currentUserCanEdit$;

    this.items$ = this.itemsService.entities$.pipe(
      map((items) => items.filter((item) => item.shippingOrderId === this.shippingOrderId)),
      map((items) => _.sortBy(items, 'id')),
    );
    this.shippingOrder$ = this.shippingOrdersService.entityMap$.pipe(
      map((shippingOrderDictionary) => shippingOrderDictionary[this.shippingOrderId]),
      filter((shippingOrder) => !!shippingOrder),
    );
    this.updateAttachments();
  }
  deleteAttachment(attachmentId: number) {
    this.shippingOrdersService.deleteAttachment(attachmentId).subscribe((deleted) => {
      if (deleted) {
        this.updateAttachments();
      }
    });
  }
  removeItem(item: ItemExtended) {
    this.itemsService.update({ ...item, shippingOrderId: null }).subscribe();
  }
  openCloneItemDialog(item: ItemExtended) {
    this.itemsService.openNewDialog(item).subscribe();
  }
  openItemNewDialog() {
    this.shippingOrder$.pipe(first()).subscribe((so) => {
      this.itemsService.openNewDialog({ shippingOrderId: this.shippingOrderId, siteId: so.siteId }).subscribe();
    });
  }
  openItemEditDialog(item: ItemExtended) {
    this.itemsService.openEditDialog(item.id).subscribe();
  }
  openShippingOrderEditDialog(shippingOrder: ShippingOrderExtended) {
    this.shippingOrdersService.openEditDialog(shippingOrder).subscribe();
  }
  openUploadAttachmentDialog(shippingOrder: ShippingOrderExtended) {
    this.shippingOrdersService.openUploadAttachmentDialog(shippingOrder).subscribe((uploaded) => {
      if (uploaded) {
        this.updateAttachments();
      }
    });
  }
  updateAttachments() {
    this.shippingOrdersService
      .getAttachmentsByShippingOrderId(this.shippingOrderId)
      .subscribe((attachments) => this.attachments$.next(attachments));
  }
}
