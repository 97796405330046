export const environment = {
  production: true,
  appInsights: {
    instrumentationKey: '34d4dc93-afcf-482c-85eb-52195975f495',
  },
  azureAd: {
    clientId: '417b8a13-d94c-4def-b14a-a9578f09a6af',
    domain: 'smartronix.com',
    tenantId: '6103e25f-39cc-4596-8d2c-444265d649a2',
  },
  siteUrl: 'https://diogenes.teaminc.biz',
};
