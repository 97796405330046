import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Building, Room } from 'src/app/models';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-room-dialog',
  templateUrl: './room-dialog.component.html',
  styles: [``],
})
export class RoomDialogComponent implements OnInit {
  public form: FormGroup;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<RoomDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { room: Room; title: string; rooms: Room[]; buildings: Building[] },
  ) {}

  ngOnInit() {
    const formFields: { [key: string]: any } = {
      name: [this.data.room.name, Validators.required],
      buildingId: [this.data.room.buildingId, Validators.required],
    };
    if (this.data.room.id) {
      formFields.id = [this.data.room.id];
      formFields.createdBy = [this.data.room.createdBy];
      formFields.createdTime = [this.data.room.createdTime];
    }
    this.form = this.fb.group(formFields);
  }
  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.form.value);
  }
}
