import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Vendor } from 'src/app/models';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-vendor-dialog',
  templateUrl: './vendor-dialog.component.html',
  styles: [``],
})
export class VendorDialogComponent implements OnInit {
  public form: FormGroup;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<VendorDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { vendor: Vendor; title: string; vendors: Vendor[] },
  ) {}

  ngOnInit() {
    const formFields: { [key: string]: any } = {
      name: [this.data.vendor.name, Validators.required],
    };
    if (this.data.vendor.id) {
      formFields.id = [this.data.vendor.id];
      formFields.createdBy = [this.data.vendor.createdBy];
      formFields.createdTime = [this.data.vendor.createdTime];
    }
    this.form = this.fb.group(formFields);
  }
  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.form.value);
  }
}
