<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>{{ data.title }}</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <div class="form-group">
      <label for="code">Code</label> <input cdkFocusInitial formControlName="code" type="text" class="form-control" />
      <p *ngIf="form.get('code').invalid && form.get('code').dirty" class="text-danger">
        A Code for this project is required
      </p>
    </div>
    <div class="form-group">
      <label for="contractNumber">Contract Number</label>
      <input formControlName="contractNumber" type="text" class="form-control" />
      <p *ngIf="form.get('contractNumber').invalid && form.get('contractNumber').dirty" class="text-danger">
        A Contract Number for this project is required
      </p>
    </div>
    <div class="form-group">
      <label for="tdlNumber">TDL Number</label> <input formControlName="tdlNumber" type="text" class="form-control" />
      <p *ngIf="form.get('tdlNumber').invalid && form.get('tdlNumber').dirty" class="text-danger">
        A Technical Direction Letter Number for this project is required
      </p>
    </div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary float-right btn-sm">
      <fa-icon [icon]="faSave"></fa-icon> Save
    </button>
  </div>
</form>
