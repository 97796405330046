import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ManufacturerDialogComponent } from '../components/manufacturers/manufacturer-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { Manufacturer } from '../models';

@Injectable({ providedIn: 'root' })
export class ManufacturersService extends EntityCollectionServiceBase<Manufacturer> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, public dialog: MatDialog) {
    super('Manufacturer', serviceElementsFactory);
  }
  delete(entity: string | number | Manufacturer, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  openNewDialog(): Observable<Manufacturer> {
    return this.entities$.pipe(
      first(),
      switchMap((manufacturers: Manufacturer[]) => {
        const dialogRef = this.dialog.open(ManufacturerDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { manufacturer: { name: null }, title: 'Create New Manufacturer', manufacturers },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          of(result);
        }
      }),
    );
  }
  openEditDialog(manufacturer: Manufacturer): Observable<Manufacturer> {
    return this.entities$.pipe(
      first(),
      switchMap((manufacturers: Manufacturer[]) => {
        const dialogRef = this.dialog.open(ManufacturerDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { manufacturer, title: 'Edit Manufacturer', manufacturers },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          of(result);
        }
      }),
    );
  }
}
