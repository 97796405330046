import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Site } from '../models';
import { Observable, combineLatest } from 'rxjs';
import { UsersService } from './users.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SitesService extends EntityCollectionServiceBase<Site> {
  public userSites$: Observable<Site[]>;
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private usersService: UsersService) {
    super('Site', serviceElementsFactory);
    this.userSites$ = combineLatest([this.entities$, this.usersService.currentUser$]).pipe(
      map(([sites, currentUser]) => {
        return sites.filter((site) => currentUser.siteIds.includes(site.id));
      }),
    );
  }
}
