import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShippingLocation } from 'src/app/models';
import { ItemsService } from 'src/app/services/items.service';
import { ShippingLocationsService } from 'src/app/services/shipping-locations.service';

interface ExtendedShippingLocation extends ShippingLocation {
  itemCount: number;
}

@Component({
  selector: 'app-shipping-locations',
  templateUrl: './shipping-locations.component.html',
  styles: [],
})
export class ShippingLocationsComponent implements OnInit {
  public gridData$: Observable<GridDataResult>;
  public filterValue$: Observable<string>;
  public loaded$: Observable<boolean>;
  public pageSize = 40;
  public rowHeight = 30;
  public skip$ = new BehaviorSubject<number>(0);
  public sort$ = new BehaviorSubject<SortDescriptor[]>([
    {
      field: 'number',
      dir: 'asc',
    },
  ]);

  constructor(
    public dialog: MatDialog,
    public shippingLocationsService: ShippingLocationsService,
    private itemsService: ItemsService,
  ) {}

  ngOnInit() {
    this.loaded$ = this.shippingLocationsService.loaded$;
    this.filterValue$ = this.shippingLocationsService.filter$;

    const extendedShippingLocations$ = combineLatest(
      this.shippingLocationsService.filteredEntities$,
      this.itemsService.entities$,
    ).pipe(
      map(([shippingLocations, items]) => {
        const itemsGroupedByShippingLocation = _.groupBy(items, 'shippingLocationId');
        return shippingLocations.map((shippingLocation) => {
          return {
            ...shippingLocation,
            itemCount: itemsGroupedByShippingLocation[shippingLocation.id]
              ? itemsGroupedByShippingLocation[shippingLocation.id].length
              : 0,
          };
        });
      }),
    );

    this.gridData$ = combineLatest(extendedShippingLocations$, this.sort$, this.skip$).pipe(
      map(([shippingLocations, [sort], skip]) => {
        const data =
          sort.dir === 'asc'
            ? _.sortBy(shippingLocations, sort.field)
            : _.sortBy(shippingLocations, sort.field).reverse();

        const pagedData = data.slice(skip, skip + this.pageSize);
        return { data: pagedData, total: data.length };
      }),
    );
  }
  openNewDialog() {
    this.shippingLocationsService.openNewDialog().subscribe();
  }
  openEditDialog(shippingLocation: ShippingLocation) {
    this.shippingLocationsService.openEditDialog(shippingLocation).subscribe();
  }
  delete(shippingLocation: ShippingLocation) {
    this.shippingLocationsService.delete(shippingLocation);
  }
  filterShippingLocations(filter) {
    this.shippingLocationsService.setFilter(filter);
  }
  public sortChange(sort: SortDescriptor[]) {
    this.sort$.next(sort);
  }

  public pageChange(event: PageChangeEvent) {
    this.skip$.next(event.skip);
  }
}
