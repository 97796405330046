import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';

/// Credit to https://medium.com/@tomsu/angular-techniques-improve-submit-buttons-ux-by-not-disabling-it-896992a94a38

/**
 * Add help text like the following bellow an input in a formgroup
 * <p *ngIf="form.get('description').invalid && form.get('description').dirty" class="text-danger">Please describe the finding.</p>
 *
 * And add this to your submit button
 * <button type="button" class="btn btn-primary" (makeSubmitIfValid)="saveFinding()" [form]="form">
 *      <i class="fa fa-check-square" aria-hidden="true"></i> Save</button>
 */

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[submitIfValid]',
})
export class SubmitIfValidDirective {
  @Input()
  form: FormGroup;
  @Output('submitIfValid')
  valid = new EventEmitter<void>(); // tslint:disable-line:no-output-rename
  @Output()
  focus = new EventEmitter<void>();

  constructor(private formRef: NgForm) {}

  @HostListener('click')
  handleClick() {
    this.markFieldsAsDirty();
    this.emitIfValid();
  }

  private markFieldsAsDirty() {
    Object.keys(this.form.controls).forEach(fieldName => {
      this.form.controls[fieldName].markAsDirty();
      if (this.form.controls[fieldName]['controls']) {
        Object.keys(this.form.controls[fieldName]['controls']).forEach(childFieldName =>
          this.form.controls[fieldName]['controls'][childFieldName].markAsDirty(),
        );
      }
    });
  }

  private emitIfValid() {
    if (this.form.valid) {
      this.valid.emit();
    } else {
      this.focus.emit();
    }
  }
}
