<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>{{ data.title }}</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <div class="form-group">
      <label for="username">Email</label>
      <input cdkFocusInitial formControlName="username" type="email" class="form-control" />
      <p *ngIf="form.get('username').invalid && form.get('username').dirty" class="text-danger">
        A Username for this user is required
      </p>
    </div>
    <div class="form-group">
      <label for="firstName">First Name</label> <input formControlName="firstName" type="text" class="form-control" />
      <p *ngIf="form.get('firstName').invalid && form.get('firstName').dirty" class="text-danger">
        A First Name for this user is required
      </p>
    </div>
    <div class="form-group">
      <label for="lastName">Last Name</label> <input formControlName="lastName" type="text" class="form-control" />
      <p *ngIf="form.get('lastName').invalid && form.get('lastName').dirty" class="text-danger">
        A Last Name for this user is required
      </p>
    </div>
    <div class="alert alert-primary" role="alert">
      Note: Only Roles and Sites can be updated through the application. All user information is managed by the
      organization.
    </div>
    <div class="form-group">
      <label for="role">Role</label>
      <select formControlName="role" class="form-control">
        <option [ngValue]="null">None</option>
        <option *ngFor="let role of applicationRoles" [ngValue]="role">{{ role }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="role">Sites</label>
      <kendo-multiselect
        [data]="sites$ | async"
        [textField]="'name'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        formControlName="siteIds"
      ></kendo-multiselect>
      <p *ngIf="form.get('siteIds').invalid && form.get('siteIds').dirty" class="text-danger">
        At least 1 site is required to be associated with this user
      </p>
    </div>
  </mat-dialog-content>

  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary float-right btn-sm">
      <fa-icon [icon]="faSave"></fa-icon> Save
    </button>
  </div>
</form>
