import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { combineLatest, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { RoomDialogComponent } from '../components/rooms/room-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { Room } from '../models';
import { BuildingsService } from './buildings.service';

@Injectable({ providedIn: 'root' })
export class RoomsService extends EntityCollectionServiceBase<Room> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    public dialog: MatDialog,
    private buildingsService: BuildingsService,
  ) {
    super('Room', serviceElementsFactory);
  }
  delete(entity: string | number | Room, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  openNewDialog(buildingId: number = null): Observable<Room> {
    return combineLatest(this.entities$, this.buildingsService.entities$).pipe(
      first(),
      switchMap(([rooms, buildings]) => {
        const dialogRef = this.dialog.open(RoomDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { room: { name: null, buildingId }, title: 'Create New Room', rooms, buildings },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  openEditDialog(room: Room): Observable<Room> {
    return combineLatest(this.entities$, this.buildingsService.entities$).pipe(
      first(),
      switchMap(([rooms, buildings]) => {
        const dialogRef = this.dialog.open(RoomDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { room, title: 'Edit Room', rooms, buildings },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
}
