import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, of, combineLatest } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { BuildingDialogComponent } from '../components/buildings/building-dialog.component';
import { ConfirmDeleteDialogComponent } from '../components/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import { Building } from '../models';
import { SitesService } from './sites.service';

@Injectable()
export class BuildingsService extends EntityCollectionServiceBase<Building> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    public dialog: MatDialog,
    private sitesService: SitesService,
  ) {
    super('Building', serviceElementsFactory);
  }
  delete(entity: string | number | Building, options?: EntityActionOptions) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    const delete$ = dialogRef.afterClosed().pipe(
      switchMap((confirmation) => {
        if (confirmation) {
          return super.delete(entity as any, options);
        } else {
          return of(null);
        }
      }),
    );
    delete$.subscribe();
    return delete$;
  }
  getAll() {
    const query = super.getWithQuery({
      $expand: 'site',
    });
    query.pipe(tap(() => this.setLoaded(true))).subscribe();
    return query;
  }
  openNewDialog(siteId: number = null): Observable<Building> {
    return combineLatest([this.entities$, this.sitesService.userSites$]).pipe(
      first(),
      switchMap(([buildings, sites]) => {
        const dialogRef = this.dialog.open(BuildingDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: {
            building: { number: null, siteId: siteId || sites[0]?.id },
            title: 'Create New Building',
            buildings,
            sites,
          },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.add(result);
        } else {
          return of(result);
        }
      }),
    );
  }
  openEditDialog(building: Building): Observable<Building> {
    return combineLatest([this.entities$, this.sitesService.userSites$]).pipe(
      first(),
      switchMap(([buildings, sites]) => {
        const dialogRef = this.dialog.open(BuildingDialogComponent, {
          minWidth: '50%',
          disableClose: true,
          data: { building, title: 'Edit Building', buildings, sites },
        });

        return dialogRef.afterClosed();
      }),
      switchMap((result) => {
        if (result) {
          return this.update(result);
        } else {
          return of(result);
        }
      }),
    );
  }
}
