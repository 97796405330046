import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { PurchaseOrderExtended, Site } from 'src/app/models';
import { PurchaseOrdersService } from 'src/app/services/purchase-orders.service';
import { SitesService } from 'src/app/services/sites.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./purchase-orders.component.scss'],
})
export class PurchaseOrdersComponent implements OnInit {
  public currentUserCanEdit$: Observable<boolean>;
  public currentUserIsAdmin$: Observable<boolean>;
  public filterValue$: Observable<string>;
  public form: FormGroup;
  public gridData$: Observable<GridDataResult>;
  public loaded$: Observable<boolean>;
  public pageSize = 60;
  public rowHeight = 30;
  public skip$ = new BehaviorSubject<number>(0);
  public userSites$: Observable<Site[]>;
  public sort$ = new BehaviorSubject<SortDescriptor[]>([
    {
      field: 'number',
      dir: 'asc',
    },
  ]);

  constructor(
    public dialog: MatDialog,
    public purchaseOrdersService: PurchaseOrdersService,
    private userService: UsersService,
    private sitesService: SitesService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.loaded$ = this.purchaseOrdersService.loaded$;
    this.filterValue$ = this.purchaseOrdersService.filter$;
    this.currentUserCanEdit$ = this.userService.currentUserCanEdit$;
    this.currentUserIsAdmin$ = this.userService.currentUserIsAdmin$;
    this.purchaseOrdersService.getAll();
    this.userSites$ = this.sitesService.userSites$;

    this.form = this.fb.group({
      siteFilter: [null],
    });

    const siteFilterChanged$: Observable<number> = this.form.get('siteFilter').valueChanges.pipe(
      startWith(null),
      distinctUntilChanged(),
      tap(() => {
        // Reset page to top
        this.skip$.next(0);
      }),
    );

    this.gridData$ = combineLatest(
      this.purchaseOrdersService.filteredEntities$,
      siteFilterChanged$,
      this.sort$,
      this.skip$,
    ).pipe(
      map(([purchaseOrders, siteFilter, [sort], skip]) => {
        const data =
          sort.dir === 'asc' ? _.sortBy(purchaseOrders, sort.field) : _.sortBy(purchaseOrders, sort.field).reverse();

        const filteredData = data.filter((purchaseOrder) => {
          if (siteFilter) {
            return purchaseOrder.siteId === siteFilter;
          } else {
            return true;
          }
        });

        const pagedData = filteredData.slice(skip, skip + this.pageSize);
        return { data: pagedData, total: filteredData.length };
      }),
    );
  }
  openNewDialog() {
    this.purchaseOrdersService.openNewDialog().subscribe();
  }
  openEditDialog(purchaseOrder: PurchaseOrderExtended) {
    this.purchaseOrdersService.openEditDialog(purchaseOrder).subscribe();
  }
  delete(purchaseOrder: PurchaseOrderExtended) {
    this.purchaseOrdersService.delete(purchaseOrder.id);
  }
  filterPurchaseOrders(filter) {
    this.purchaseOrdersService.setFilter(filter);
  }
  public sortChange(sort: SortDescriptor[]) {
    this.sort$.next(sort);
  }

  public pageChange(event: PageChangeEvent) {
    this.skip$.next(event.skip);
  }
}
