import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

const REQUIRED_CLASS = 'app-required-label';

/**
 * Use me like this:
 * <label [labelRequired]="form.get('purpose')" for="purpose">Purpose for something...*</label>
 */
@Directive({
  selector: '[labelRequired]',
})
export class LabelRequiredDirective implements OnChanges {
  @Input() makeLabel: FormControl;
  constructor(private el: ElementRef) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.makeLabel && this.el.nativeElement) {
      const required = this.hasRequiredField(this.makeLabel);
      this.decorate(required);
    }
  }
  private decorate(isRequired: boolean) {
    // Clear class if already added
    this.el.nativeElement.classList.remove(REQUIRED_CLASS);
    if (isRequired) {
      this.el.nativeElement.classList.add(REQUIRED_CLASS);
    }
  }

  // Check if form control has required validator
  private hasRequiredField(abstractControl: AbstractControl) {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (abstractControl['controls'][controlName]) {
          if (this.hasRequiredField(abstractControl['controls'][controlName])) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
