<div class="card" id="shipping-orders">
  <h5 class="card-header diog-grid-header">
    <div class="card-title">
      Shipping Orders <span class="badge badge-pill badge-secondary">{{ (gridData$ | async)?.total }}</span>
    </div>
    <div class="card-controls d-inline-flex">
      <app-grid-filter-input
        [ngModel]="filterValue$ | async"
        #filter
        (keyup)="filterShippingOrders(filter.value)"
        style="margin-right: 10px;"
      ></app-grid-filter-input>
      <ng-form [formGroup]="form" *ngIf="(userSites$ | async)?.length > 1">
        <kendo-combobox
          [placeholder]="'Site Filter'"
          id="site-picker"
          [data]="userSites$ | async"
          [valueField]="'id'"
          [textField]="'name'"
          [filterable]="true"
          formControlName="siteFilter"
          [valuePrimitive]="true"
          [clearButton]="true"
          [suggest]="false"
        >
        </kendo-combobox>
        &nbsp;
      </ng-form>
      <div>
        &nbsp;
        <button
          *ngIf="currentUserCanEdit$ | async"
          type="button"
          (click)="openNewDialog()"
          class="btn btn-outline-primary"
        >
          <i class="far fa-plus"></i> New
        </button>
      </div>
    </div>
  </h5>

  <kendo-grid
    [data]="gridData$ | async"
    [rowHeight]="rowHeight"
    style="height: calc(100vh - 180px);"
    [resizable]="true"
    [sortable]="{
      allowUnsort: true,
      mode: 'single'
    }"
    [sort]="sort$ | async"
    [scrollable]="'virtual'"
    [loading]="!(loaded$ | async)"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="skip$ | async"
    (sortChange)="sortChange($event)"
    (pageChange)="pageChange($event)"
  >
    <kendo-grid-column field="number" title="Shipping Order">
      <ng-template kendoGridCellTemplate let-shippingOrder>
        <div>
          <a class="pointer text-primary" [routerLink]="['/shipping-order', shippingOrder.id]">
            {{ shippingOrder.number }}
          </a>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="status" title="Status"></kendo-grid-column>
    <kendo-grid-column field="shippingDate" title="Shipping Date">
      <ng-template kendoGridCellTemplate let-shippingOrder>
        {{ shippingOrder.shippingDate | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="shippingLocationName" title="Shipping Location"></kendo-grid-column>
    <kendo-grid-column field="projectCode" title="Project"></kendo-grid-column>
    <kendo-grid-column field="itemCount" title="Item Count"></kendo-grid-column>
    <kendo-grid-column field="siteName" title="Site"></kendo-grid-column>
    <kendo-grid-column field="createdBy" title="Created By">
      <ng-template kendoGridCellTemplate let-shippingOrder>
        <app-user-name [userId]="shippingOrder.createdBy"></app-user-name>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="130" field="createdTime" title="Created">
      <ng-template kendoGridCellTemplate let-shippingOrder>
        {{ shippingOrder.createdTime | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedBy" title="Updated By">
      <ng-template kendoGridCellTemplate let-shippingOrder>
        <app-user-name [userId]="shippingOrder.updatedBy"></app-user-name>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="130" field="updatedTime" title="Updated">
      <ng-template kendoGridCellTemplate let-shippingOrder>
        {{ shippingOrder.updatedTime | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100" title="Actions" *ngIf="currentUserCanEdit$ | async">
      <ng-template kendoGridCellTemplate let-shippingOrder>
        <div class="no-wrap">
          <button
            (click)="openEditDialog(shippingOrder)"
            title="Edit"
            type="button"
            class="btn btn-outline-primary btn-sm no-wrap"
          >
            <i class="far fa-edit"></i>
          </button>
          &nbsp;
          <button
            *ngIf="currentUserIsAdmin$ | async"
            (click)="delete(shippingOrder)"
            title="Delete"
            type="button"
            class="btn btn-outline-danger btn-sm no-wrap"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
