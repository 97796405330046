<mat-dialog-content>
  <h4>
    <i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
    Delete Confirmation
  </h4>
  <h6>Are you sure you want to delete?</h6>
</mat-dialog-content>
<div class="text-center">
  <button id="delete-cancel-button" type="button" class="btn btn-default pointer" (click)="cancel()">
    <i class="fa fa-times" aria-hidden="true"></i>
    Cancel
  </button>
  &nbsp;
  <button id="delete-confirmation-button" type="button" class="btn btn-danger pointer" (click)="confirm()">
    <i class="fa fa-trash fa-lg" aria-hidden="true"></i>
    Delete
  </button>
</div>
