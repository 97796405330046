import { Component, OnInit, SecurityContext } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Report } from 'src/app/models';
import { ReportsService } from 'src/app/services/reports.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface ReportWithSafeUrl extends Report {
  safeUrl: SafeResourceUrl;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styles: [``],
})
export class ReportComponent implements OnInit {
  public loaded$: Observable<boolean>;
  public report$: Observable<ReportWithSafeUrl>;
  public reports$: Observable<Report[]>;

  constructor(private reportsService: ReportsService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.loaded$ = this.reportsService.loaded$;
    this.reportsService.getAll().subscribe();
    this.reports$ = this.reportsService.entities$;

    this.report$ = combineLatest([this.reports$, this.route.params]).pipe(
      map(([reports, params]) => {
        console.log(params);
        const report = reports.find((r) => r.id === +params.id);
        if (!report) {
          return;
        }
        const safeReport = { ...report, safeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(report.url) };
        console.log(safeReport);
        return safeReport;
      }),
    );
  }
}
