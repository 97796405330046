import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Project, PurchaseOrder, Rip, Vendor, Site } from 'src/app/models';
import { VendorsService } from 'src/app/services/vendors.service';
import { convertToUtcDates, defaultDate } from '../../services/utility.service';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-purchase-order-edit-dialog',
  templateUrl: './purchase-order-dialog.component.html',
  styles: [``],
})
export class PurchaseOrderDialogComponent implements OnInit {
  public form: FormGroup;
  public title = 'Edit Receiving Document';
  public vendors$: Observable<Vendor[]>;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<PurchaseOrderDialogComponent>,
    private fb: FormBuilder,
    private vendorsService: VendorsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      purchaseOrder: PurchaseOrder;
      title: string;
      rips: Rip[];
      projects: Project[];
      sites: Site[];
      classificationOptions: string[];
    },
  ) {}

  ngOnInit() {
    this.vendors$ = this.vendorsService.entities$;
    const formFields: { [key: string]: any } = {
      classification: [this.data.purchaseOrder.classification],
      deliveryDate: [defaultDate(this.data.purchaseOrder.deliveryDate)],
      notes: [this.data.purchaseOrder.notes],
      number: [this.data.purchaseOrder.number, Validators.required],
      orderDate: [defaultDate(this.data.purchaseOrder.orderDate)],
      projectId: [this.data.purchaseOrder.projectId, Validators.required],
      ripNumber: [this.data.purchaseOrder.ripNumber],
      shippingDate: [defaultDate(this.data.purchaseOrder.shippingDate)],
      status: [this.data.purchaseOrder.status],
      siteId: [this.data.purchaseOrder.siteId, Validators.required],
      trackingNumber: [this.data.purchaseOrder.trackingNumber],
      vendorId: [this.data.purchaseOrder.vendorId],
      vendorOrderNumber: [this.data.purchaseOrder.vendorOrderNumber],
    };
    if (this.data.purchaseOrder.id) {
      formFields.id = [this.data.purchaseOrder.id];
      formFields.createdBy = [this.data.purchaseOrder.createdBy];
      formFields.createdTime = [this.data.purchaseOrder.createdTime];
    }
    this.form = this.fb.group(formFields);
  }
  cancel() {
    this.dialogRef.close(null);
  }

  openNewVendorModal() {
    this.vendorsService.openNewDialog().subscribe((vendor) => {
      if (vendor) {
        this.form.get('vendorId').setValue(vendor.id);
      }
    });
  }

  save() {
    this.dialogRef.close(convertToUtcDates(this.form.value, ['deliveryDate', 'orderDate', 'shippingDate']));
  }
}
