<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>{{ data.title }}</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <div class="form-group">
      <label for="firstName">First Name</label>
      <input cdkFocusInitial formControlName="firstName" type="text" class="form-control" />
      <p *ngIf="form.get('firstName').invalid && form.get('firstName').dirty" class="text-danger">
        A First Name is required
      </p>
    </div>
    <div class="form-group">
      <label for="lastName">Last Name</label>
      <input formControlName="lastName" type="text" class="form-control" />
      <p *ngIf="form.get('lastName').invalid && form.get('lastName').dirty" class="text-danger">
        A Last Name is required
      </p>
    </div>
    <div class="form-group">
      <label for="emailAddress">Email Address</label>
      <input formControlName="emailAddress" type="email" class="form-control" />
    </div>
    <div class="form-group">
      <label for="phoneNumber">Phone Number</label>
      <input formControlName="phoneNumber" type="tel" class="form-control" />
    </div>
    <div class="form-group">
      <label for="notes">Notes</label>
      <textarea autosize formControlName="notes" class="form-control form-control-sm"></textarea>
    </div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary float-right btn-sm">
      <fa-icon [icon]="faSave"></fa-icon> Save
    </button>
  </div>
</form>
