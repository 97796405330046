import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { User, Report } from 'src/app/models';
import * as fromRoot from '../../reducers';
import { UsersService } from 'src/app/services/users.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ItemsService } from 'src/app/services/items.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styles: [
    `
      a.navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
      }

      html {
        font-size: 14px;
      }
      @media (min-width: 768px) {
        html {
          font-size: 16px;
        }
      }

      .box-shadow {
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
      }
    `,
  ],
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  currentUser$: Observable<User>;
  currentUserIsAdmin$: Observable<boolean>;
  currentUserIsAppAdmin$: Observable<boolean>;
  cartCount$: Observable<number>;
  reports$: Observable<Report[]>;
  constructor(
    private usersService: UsersService,
    private reportsService: ReportsService,
    private store: Store<fromRoot.State>,
    private itemsService: ItemsService,
  ) {}

  ngOnInit() {
    this.currentUser$ = this.usersService.currentUser$;
    this.currentUserIsAdmin$ = this.usersService.currentUserIsAdmin$;
    this.currentUserIsAppAdmin$ = this.usersService.currentUserIsAppAdmin$;
    this.cartCount$ = combineLatest([this.store.select(fromRoot.selectCartIds), this.itemsService.entityMap$]).pipe(
      map(([cartIds, itemMap]) => cartIds.filter((id) => !!itemMap[id]).length),
    );
    this.reports$ = this.reportsService.entities$;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  signOut() {
    this.usersService.logout();
  }
}
