import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShippingOrder } from 'src/app/models';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-add-cart-to-shipping-order-dialog',
  templateUrl: './add-cart-to-shipping-order-dialog.component.html',
  styleUrls: ['./add-cart-to-shipping-order-dialog.component.scss'],
})
export class AddCartToShippingOrderDialogComponent implements OnInit {
  public form: FormGroup;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<AddCartToShippingOrderDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shippingOrders: ShippingOrder[];
    },
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      shippingOrderId: [null, Validators.required],
    });
  }
  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.form.value.shippingOrderId);
  }
}
