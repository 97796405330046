import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from 'src/app/models';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styles: [``],
})
export class ReportDialogComponent implements OnInit {
  public form: FormGroup;
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<ReportDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { report: Report; title: string; reports: Report[] },
  ) {}

  ngOnInit() {
    const formFields: { [key: string]: any } = {
      name: [this.data.report.name, Validators.required],
      description: [this.data.report.description],
      url: [this.data.report.url, Validators.required],
    };
    if (this.data.report.id) {
      formFields.id = [this.data.report.id];
    }
    this.form = this.fb.group(formFields);
  }
  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.form.value);
  }
}
