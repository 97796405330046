<div class="card">
  <h5 class="card-header">
    <div class="card-controls float-right">
      <app-grid-filter-input
        [ngModel]="filterValue$ | async"
        #filter
        (keyup)="filterRips(filter.value)"
        style="margin-right: 10px;"
      ></app-grid-filter-input>
      <button type="button" (click)="openNewDialog()" class="btn btn-outline-primary btn-sm">
        <i class="far fa-plus"></i> New
      </button>
    </div>
    <div class="card-title">
      RIPs <span class="badge badge-pill badge-secondary">{{ (gridData$ | async)?.total }}</span>
    </div>
  </h5>

  <kendo-grid
    [data]="gridData$ | async"
    [rowHeight]="rowHeight"
    style="height: calc(100vh - 180px);"
    [resizable]="true"
    [sortable]="{
      allowUnsort: true,
      mode: 'single'
    }"
    [sort]="sort$ | async"
    [scrollable]="'virtual'"
    [loading]="!(loaded$ | async)"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="skip$ | async"
    (sortChange)="sortChange($event)"
    (pageChange)="pageChange($event)"
  >
    <kendo-grid-column field="number" title="Number"> </kendo-grid-column>
    <kendo-grid-column [width]="80" title="Actions">
      <ng-template kendoGridCellTemplate let-rip>
        <div class="no-wrap">
          <!-- <button
            (click)="openEditDialog(rip)"
            title="Edit"
            type="button"
            class="btn btn-outline-primary btn-sm no-wrap"
          >
            <i class="far fa-edit"></i>
          </button>
          &nbsp; -->
          <button (click)="delete(rip)" title="Delete" type="button" class="btn btn-outline-danger btn-sm no-wrap">
            <i class="far fa-trash-alt"></i>
          </button>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
