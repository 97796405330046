import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';
import { Manufacturer, Part } from 'src/app/models';
import { ManufacturersService } from 'src/app/services/manufacturers.service';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-part-dialog',
  templateUrl: './part-dialog.component.html',
  styles: [``],
})
export class PartDialogComponent implements OnInit {
  public form: FormGroup;
  public manufacturers$: Observable<Manufacturer[]>;
  public faTimes = faTimes;
  public faSave = faSave;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  constructor(
    public dialogRef: MatDialogRef<PartDialogComponent>,
    private fb: FormBuilder,
    private manufacturersService: ManufacturersService,
    @Inject(MAT_DIALOG_DATA)
    public data: { part: Part; title: string; conditionCodeOptions: string[] },
  ) {}

  ngOnInit() {
    this.manufacturers$ = this.manufacturersService.entities$;
    const formFields: { [key: string]: any } = {
      conditionCode: [this.data.part.conditionCode],
      description: [this.data.part.description],
      manufacturerId: [this.data.part.manufacturerId],
      nsn: [this.data.part.nsn],
      partNumber: [this.data.part.partNumber, Validators.required],
      unitOfIssue: [this.data.part.unitOfIssue, Validators.required],
    };
    if (this.data.part.id) {
      formFields.id = [this.data.part.id];
      formFields.createdBy = [this.data.part.createdBy];
      formFields.createdTime = [this.data.part.createdTime];
    }
    this.form = this.fb.group(formFields);
  }
  cancel() {
    this.dialogRef.close(null);
  }

  openNewManufacturersModal() {
    this.manufacturersService.openNewDialog().subscribe((manufacturer) => {
      if (manufacturer) {
        this.form.get('manufacturerId').setValue(manufacturer.id);
      }
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }
}
