<div class="container" *ngIf="shippingOrder">
  <div data-size="A4">
    <div class="float-right po-header-controls d-print-none" *ngIf="currentUserCanEdit">
      <button
        (click)="openUploadAttachmentDialog.next(shippingOrder)"
        type="button"
        class="btn btn-outline-primary btn-sm"
      >
        <i class="far fa-paperclip"></i> Add Attachments
      </button>
      <button
        (click)="openShippingOrderEditDialog.next(shippingOrder)"
        type="button"
        class="btn btn-outline-primary btn-sm"
      >
        <i class="far fa-edit"></i> Edit Shipping Order
      </button>
    </div>
    <h3>Shipping Order #{{ shippingOrder.number }}</h3>
    <div class="row po-header-row">
      <div *ngIf="shippingOrder.projectCode" class="col-sm-6 po-details">
        <span class="label">Name</span>
        {{ shippingOrder.shippingLocationName }} <br />

        <span class="label">Project</span>
        {{ shippingOrder.projectCode }} <br />

        <span class="label">Item Count</span>
        {{ (items$ | async)?.length }} <br />

        <span class="label">Shipping Date</span>
        {{ (shippingOrder.shippingDate | date) || 'N/A' }}
      </div>
      <div class="col-sm-6 po-details" *ngIf="shippingOrder.shippingLocationName">
        <span class="label">Address</span><br />
        {{ shippingOrder.shippingLocationAddress1 }} <br />
        <ng-container *ngIf="shippingOrder.shippingLocationAddress2">
          {{ shippingOrder.shippingLocationAddress2 }} <br />
        </ng-container>
        {{ shippingOrder.shippingLocationCity }} {{ shippingOrder.shippingLocationState }},
        {{ shippingOrder.shippingLocationZip }}
      </div>
    </div>
    <div class="po-header-row">
      <div class="po-details">
        <span class="label">Notes</span>
        {{ shippingOrder.notes }}
      </div>
    </div>
    <div class="po-header-row" *ngIf="attachments.length > 0">
      <div class="po-details">
        <span class="label">Attachments</span>
        <div class="row">
          <div *ngFor="let attachment of attachments" class="col-sm-6 attachments">
            <a download href="/api/ShippingOrderAttachments/{{ attachment.id }}" class="attachment">
              <i class="far fa-paperclip"></i> {{ attachment.fileName }}</a
            >
            <button
              *ngIf="currentUserCanEdit"
              title="Delete Attachment"
              (click)="deleteAttachment.next(attachment.id)"
              class="btn btn-outline-danger btn-sm"
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <kendo-grid
      [data]="gridData$ | async"
      [rowHeight]="rowHeight"
      style="height: calc(100vh - 180px);"
      [resizable]="true"
      [sortable]="{
        allowUnsort: true,
        mode: 'single'
      }"
      [sort]="sort$ | async"
      [scrollable]="'virtual'"
      [pageable]="true"
      [pageSize]="pageSize"
      [skip]="skip$ | async"
      (sortChange)="sortChange($event)"
      (pageChange)="pageChange($event)"
    >
      <kendo-grid-column field="partNumber" title="Part Number"></kendo-grid-column>
      <kendo-grid-column field="manufacturerName" title="Manufacturer"></kendo-grid-column>
      <kendo-grid-column field="partDescription" title="Description"></kendo-grid-column>
      <kendo-grid-column field="serialNumber" title="Serial Number"></kendo-grid-column>
      <kendo-grid-column field="orderQuantity" title="Quantity"></kendo-grid-column>
      <kendo-grid-column field="partUnitOfIssue" title="U/I"></kendo-grid-column>
      <kendo-grid-column field="unitPrice" title="Unit Price">
        <ng-template kendoGridCellTemplate let-item>{{ item.unitPrice | currency }}</ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="totalCost" title="Total Cost">
        <ng-template kendoGridCellTemplate let-item>{{ item.totalCost | currency }}</ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="130" field="createdTime" title="Created">
        <ng-template kendoGridCellTemplate let-item>
          {{ item.createdTime | date }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="140" title="Actions">
        <ng-template kendoGridCellTemplate let-item>
          <div class="no-wrap">
            <button
              (click)="openItemEditDialog.next(item)"
              title="Edit"
              type="button"
              class="btn btn-outline-primary btn-sm no-wrap"
            >
              <i class="far fa-edit"></i>
            </button>
            <ng-container *ngIf="currentUserCanEdit">
              &nbsp;
              <button
                (click)="removeItem.next(item)"
                title="Remove from Shipping Order"
                type="button"
                class="btn btn-outline-warning btn-sm no-wrap"
              >
                <i class="far fa-undo"></i>
              </button>
            </ng-container>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
