<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>{{ data.title }}</h3>
  </div>
  <mat-dialog-content class="modal-dialog-content">
    <div class="form-group">
      <label for="custodianId">Custodian</label>
      <a
        *ngIf="data.currentUserCanEdit"
        (click)="openNewCustodianModal()"
        style="margin-top: 2px;"
        class="float-right small pointer text-primary"
        ><i class="far fa-plus"></i> New Custodian</a
      >
      <select cdkFocusInitial formControlName="custodianId" class="form-control form-control-sm">
        <option [ngValue]="null"></option>
        <option *ngFor="let custodian of data.custodians$ | async" [ngValue]="custodian.id"
          >{{ custodian.firstName }} {{ custodian.lastName }}</option
        >
      </select>
      <p *ngIf="form.get('custodianId').invalid && form.get('custodianId').dirty" class="text-danger">
        An custodian is required
      </p>
    </div>
    <div class="form-group">
      <label for="assignmentDate">Assignment Date</label>
      <input formControlName="assignmentDate" type="date" class="form-control form-control-sm" />
      <p *ngIf="form.get('assignmentDate').invalid && form.get('assignmentDate').dirty" class="text-danger">
        An assignment date is required
      </p>
    </div>
    <div class="form-group">
      <label for="notes">Notes</label>
      <textarea autosize formControlName="notes" class="form-control form-control-sm"></textarea>
    </div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon> Cancel
    </button>
    <button type="button" (submitIfValid)="save()" [form]="form" class="btn btn-primary float-right btn-sm">
      <fa-icon [icon]="faSave"></fa-icon> Save
    </button>
  </div>
</form>
