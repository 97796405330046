import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-nav-counter',
  template: `
    <a class="pointer" (click)="navigateToCart()" *ngIf="cartCount > 0" title="Go to Cart">
      <span class="badge badge-pill badge-danger">{{ cartCount }}</span>
      <i class="far fa-2x fa-shopping-cart"></i>
    </a>
  `,
  styleUrls: ['./cart-nav-counter.component.scss'],
})
export class CartNavCounterComponent implements OnInit {
  @Input() cartCount = 40;
  constructor(private router: Router) {}

  ngOnInit() {}
  navigateToCart() {
    this.router.navigate(['/cart']);
  }
}
