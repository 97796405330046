import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Project, ShippingLocation, ShippingOrderExtended, Site } from 'src/app/models';
import { ShippingLocationsService } from 'src/app/services/shipping-locations.service';
import { convertToUtcDates, defaultDate } from '../../services/utility.service';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-shipping-order-edit-dialog',
  templateUrl: './shipping-order-dialog.component.html',
  styles: [``],
})
export class ShippingOrderDialogComponent implements OnInit {
  public form: FormGroup;
  public shippingLocations$: Observable<ShippingLocation[]>;
  public title = 'Edit Shipping Order';
  public faTimes = faTimes;
  public faSave = faSave;
  constructor(
    public dialogRef: MatDialogRef<ShippingOrderDialogComponent>,
    private shippingLocationsService: ShippingLocationsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shippingOrder: ShippingOrderExtended;
      title: string;
      projects: Project[];
      sites: Site[];
    },
  ) {}

  ngOnInit() {
    this.shippingLocations$ = this.shippingLocationsService.entities$;
    const formFields: { [key: string]: any } = {
      notes: [this.data.shippingOrder.notes],
      number: [this.data.shippingOrder.number, Validators.required],
      projectId: [this.data.shippingOrder.projectId, Validators.required],
      shippingDate: [defaultDate(this.data.shippingOrder.shippingDate)],
      shippingLocationId: [this.data.shippingOrder.shippingLocationId, Validators.required],
      siteId: [this.data.shippingOrder.siteId, Validators.required],
      status: [this.data.shippingOrder.status],
      trackingNumber: [this.data.shippingOrder.trackingNumber],
    };
    if (this.data.shippingOrder.id) {
      formFields.id = [this.data.shippingOrder.id];
      formFields.createdBy = [this.data.shippingOrder.createdBy];
      formFields.createdTime = [this.data.shippingOrder.createdTime];
    }
    this.form = this.fb.group(formFields);
  }
  cancel() {
    this.dialogRef.close(null);
  }
  openNewShippingLocationModal() {
    this.shippingLocationsService.openNewDialog().subscribe((shippingLocation) => {
      if (shippingLocation) {
        this.form.get('shippingLocationId').setValue(shippingLocation.id);
      }
    });
  }
  save() {
    this.dialogRef.close(convertToUtcDates(this.form.value, ['shippingDate']));
  }
}
