import * as _ from 'lodash';
import * as moment from 'moment';

export function defaultDate(date: string) {
  return date ? moment.utc(date).format('YYYY-MM-DD') : null;
}

export function convertToUtcDates(object: Object, dateAttrs: string[]) {
  const copy = _.cloneDeep(object);
  dateAttrs.forEach((attr) => {
    const date = _.get(copy, attr);
    if (date) {
      _.set(copy, attr, moment(date).format());
    }
  });
  return copy;
}
